import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ChordSheetListPage from "./pages/ChordSheetListPage";
import FavoriteChordSheetListPage from "./pages/FavoriteChordSheetListPage";
import ChordLibraryPage from "./pages/ChordLibraryPage";
import ChordSheetPage from "./pages/ChordSheetPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import NoticePage from "./pages/NoticePage";
import RegisterPage from "./pages/RegisterPage";
import NavigationBar from './components/NavigationBar';
import { AuthProvider } from './contexts/AuthContext';
import './assets/styles/App.css';

function App() {
    return (
        <AuthProvider>
            <Router>
                <NavigationBar />
                <div className="custom-container">
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/register" element={<RegisterPage />} />
                        <Route path="/chord-sheets/favorites" element={<FavoriteChordSheetListPage />} />
                        <Route path="/chord-sheets/:id" element={<ChordSheetPage />} />
                        <Route path="/chord-sheets" element={<ChordSheetListPage />} />
                        <Route path="/chords" element={<ChordLibraryPage />} />
                        <Route path="/notices" element={<NoticePage />} />
                    </Routes>
                </div>
            </Router>
        </AuthProvider>
    );
}

export default App;
