import React, { useEffect, useCallback } from 'react';
import { getChordSvg, renderChordSvg } from "../utils/chordSvgUtils";
import { ChordExposureType } from "../enums/ChordExposureType";
import { Song } from "chordsheetjs";
import '../assets/styles/ChordModal.css';

interface ChordModalProps {
    chordExposureType: ChordExposureType;
    song: Song;
}

const ChordModal: React.FC<ChordModalProps> = ({ chordExposureType, song }) => {
    const handleClick = useCallback((event: MouseEvent) => {
        event.stopPropagation(); // 이벤트 전파 방지
        const target = event.currentTarget as HTMLElement;
        const mainChordText = target.getAttribute('data-main-chord');

        if (mainChordText) {
            // 기존 모달이 있으면 바로 제거
            const existingModal = document.querySelector('.image-chord--modal');
            if (existingModal) {
                existingModal.remove();
            }

            // 새로운 모달 띄우기
            const chordSvg = getChordSvg(chordExposureType, mainChordText);
            if (chordSvg) {
                const rect = target.getBoundingClientRect();
                const modal = document.createElement('div');
                modal.className = 'image-chord--modal';
                renderChordSvg(modal, chordExposureType, mainChordText);
                modal.style.position = 'absolute';
                modal.style.top = `${rect.bottom + window.scrollY}px`;
                modal.style.left = `${rect.left + window.scrollX}px`;
                document.body.appendChild(modal);

                const handleOutsideClick = (e: MouseEvent) => {
                    if (!modal.contains(e.target as Node)) {
                        modal.remove();
                        document.removeEventListener('click', handleOutsideClick);
                    }
                };

                // 다른 곳 클릭 시 모달 닫기
                document.addEventListener('click', handleOutsideClick);
            }
        }
    }, [chordExposureType]);

    useEffect(() => {
        if (song) {
            const chordElements = document.querySelectorAll('.chord');
            chordElements.forEach((chordElement) => {
                chordElement.addEventListener('click', handleClick as EventListener); // 클릭 이벤트
            });

            return () => {
                document.querySelectorAll('.image-chord--modal').forEach(modal => modal.remove());
                chordElements.forEach((chordElement) => {
                    chordElement.removeEventListener('click', handleClick as EventListener);
                });
            };
        }
    }, [song, handleClick]);

    return null;
};

export default ChordModal;
