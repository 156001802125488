import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { calculateCapoPosition } from "../utils/capoPositionUtils";
import { parseChordSheetText, toChordHtml, transposeKey } from "../utils/chordSheetUtils";
import { addFavorite, fetchChordSheet, modifyFavoriteChordKey, removeFavorite } from "../services/ChordSheetApiService";
import { ChordSheet } from "../models/ChordSheet";
import { Song } from "chordsheetjs";
import { Button, Form } from "react-bootstrap";
import { ChordExposureType, DEFAULT_CHORD_EXPOSURE_TYPE } from "../enums/ChordExposureType";
import { SESSION_KEY_CHORD_EXPOSURE_TYPE, SESSION_KEY_THEME_TYPE } from "../constants/sessionKeys";
import { useAuth } from "../contexts/AuthContext";
import { loadFromSession, saveToSession } from "../utils/sessionStorageUtils";
import '../assets/styles/ChordSheetItem.css';
import '../assets/styles/ChordSheetItemLightUI.css';
import '../assets/styles/ChordSheetItemDarkUI.css';
import '../assets/styles/ChordSheetItemAutumnUI.css';
import '../assets/styles/ChordSheetItemWinterUI.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { DEFAULT_THEME_TYPE, Themes, ThemeType } from '../enums/ThemeType';
import { TransposeType } from "../enums/TransposeType";
import ChordModal from './ChordModal';

const ChordSheetItem: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();

    const [chordSheet, setChordSheet] = useState<ChordSheet | null>(null);
    const [song, setSong] = useState<Song | null>(null);
    const [capo, setCapo] = useState<number>(0);
    const [isFavorite, setIsFavorite] = useState<boolean>(false);

    const [chordExposureType, setChordExposureType] = useState<ChordExposureType>(
        loadFromSession(SESSION_KEY_CHORD_EXPOSURE_TYPE, ChordExposureType, DEFAULT_CHORD_EXPOSURE_TYPE)
    );

    const [themeType, setThemeType] = useState<ThemeType>(
        loadFromSession(SESSION_KEY_THEME_TYPE, ThemeType, DEFAULT_THEME_TYPE)
    );

    useEffect(() => {
        if (id) {
            const getChordSheet = async () => {
                try {
                    const chordSheet = await fetchChordSheet(Number(id));
                    if (chordSheet) {
                        setChordSheet(chordSheet);
                        let song = parseChordSheetText(chordSheet.chordSheetText!!);
                        const key = chordSheet.favoriteChordKey || chordSheet.chordKey;
                        if (key) {
                            song = transposeKey(song, key);
                        }
                        setSong(song);
                        setCapo(calculateCapoPosition(chordSheet.chordKey, song.key));
                        setIsFavorite(chordSheet.favoriteYn === true);
                    }
                } catch (error) {
                    console.error(`Error fetching chordSheet: ${error}`);
                }
            };
            getChordSheet();
        }
    }, [id]);

    useEffect(() => {
        saveToSession(SESSION_KEY_CHORD_EXPOSURE_TYPE, chordExposureType);
    }, [chordExposureType]);

    useEffect(() => {
        saveToSession(SESSION_KEY_THEME_TYPE, themeType);
    }, [themeType]);

    const handleTranspose = useCallback((transposeType: TransposeType) => {
        if (song && chordSheet) {
            const transposedSong = transposeType === TransposeType.UP ? song.transposeUp() : song.transposeDown();
            setSong(transposedSong);
            setCapo(calculateCapoPosition(chordSheet.chordKey, transposedSong.key));

            if (isFavorite) {
                modifyFavoriteChordKey(Number(id), transposedSong.key!).catch(error => {
                    console.error(`Error modifying favorite chord key: ${error}`);
                });
            }
        }
    }, [song, chordSheet, isFavorite, id]);

    const handleChordExposureTypeChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
        setChordExposureType(event.target.value as ChordExposureType);
    }, []);

    const handleToggleFavorite = useCallback(async () => {
        try {
            if (isFavorite) {
                if (window.confirm('즐겨찾기 삭제 하시겠습니까?')) {
                    await removeFavorite(Number(id));
                    setIsFavorite(false);
                    handleGoBack();
                }
            } else {
                await addFavorite(Number(id));
                setIsFavorite(true);
                alert("즐겨찾기 추가 완료");
            }
        } catch (error) {
            console.error(`Error handleToggleFavorite: ${error}`);
            alert("즐겨찾기 처리간 오류 발생");
        }
    }, [isFavorite, id]);

    const handleGoBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const toggleThemeType = useCallback(() => {
        setThemeType(prevThemeType => {
            const themeTypes = Object.values(ThemeType);
            const currentIndex = themeTypes.indexOf(prevThemeType);
            const nextIndex = (currentIndex + 1) % themeTypes.length;
            return themeTypes[nextIndex];
        });
    }, []);

    return (
        <div className={`container chord-sheet ${themeType}`}>
            <div className="row justify-content-between align-items-center">
                <div className="col-auto">
                    <div className="btn-group" role="group">
                        <Button variant="primary" onClick={() => handleTranspose(TransposeType.DOWN)}>b</Button>
                        <Button variant="primary" onClick={() => handleTranspose(TransposeType.UP)}>#</Button>
                    </div>
                </div>
                <div className="col-auto">
                    <Form.Select value={chordExposureType} onChange={handleChordExposureTypeChange}>
                        <option value={ChordExposureType.TEXT_GUITAR}>Text</option>
                        <option value={ChordExposureType.TEXT_UKULELE}>Text 【U】</option>
                        <option value={ChordExposureType.IMAGE_GUITAR}>Guitar</option>
                        <option value={ChordExposureType.IMAGE_UKULELE}>Ukulele</option>
                    </Form.Select>
                </div>
                <div className="col-auto">
                    <Button variant={Themes[themeType].variant} onClick={toggleThemeType}>
                        <FontAwesomeIcon icon={Themes[themeType].icon} />
                    </Button>
                </div>
            </div>
            <div className="row justify-content-center mt-3 mb-3">
                <div className="col-auto text-center">
                    {song && (
                        <div>
                            <h5>{chordSheet!!.title}</h5>
                            <p><small>(capo: {capo === 0 ? '원키' : capo})</small></p>
                            <div dangerouslySetInnerHTML={{ __html: toChordHtml(song, chordExposureType) }} />
                        </div>
                    )}
                </div>
            </div>
            <ChordModal chordExposureType={chordExposureType} song={song!} />
            <div className="row justify-content-center">
                <div className="col-auto">
                    {song && isAuthenticated && (
                        <Button
                            variant={isFavorite ? "danger" : "primary"}
                            onClick={handleToggleFavorite}
                        >
                            <FontAwesomeIcon icon={faBookmark} /> 목록 {isFavorite ? "삭제" : "추가"}
                        </Button>
                    )}
                </div>
            </div>
            {chordSheet?.referenceUrl && (
                <div className="row justify-content-center mt-4">
                    <div className="col-auto">
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe
                                className="embed-responsive-item"
                                title={chordSheet.title}
                                src={chordSheet.referenceUrl}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChordSheetItem;
