import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { register } from '../services/AuthApiService';

const RegisterPage: React.FC = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordMatch, setPasswordMatch] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            alert("비밀번호가 일치하지 않습니다.");
            return;
        }

        if (!validateUsername(username) || !validatePassword(password)) {
            alert("입력한 정보를 확인해 주세요.");
            return;
        }

        try {
            await register({ username, password });
            alert('계정 등록이 완료되었습니다. 로그인 페이지로 이동합니다.');
            navigate('/login');
        } catch (error) {
            let message = '서버 에러가 발생하였습니다.';
            if (axios.isAxiosError(error) && error.response) {
                if (error.response.status === 409) {
                    message = error.response.data;
                } else {
                    message = error.response.data.message;
                }
            }
            alert("계정 등록 실패: " + message);
        }
    };

    const validateUsername = (username: string) => {
        // 특수문자 제외, 영문 및 숫자 4~20자
        const regex = /^[a-zA-Z0-9]{4,20}$/;
        return regex.test(username);
    };

    const validatePassword = (password: string) => {
        // 특수문자 포함 가능, 필수 아님. 영문 및 숫자 5~20자
        const regex = /^[a-zA-Z0-9!@#$%^&*]{5,20}$/;
        return regex.test(password);
    };

    const handleUsernameBlur = () => {
        if (!validateUsername(username)) {
            setUsernameError('4자 이상 20자 이하로 입력해 주세요.(영문/숫자)');
        } else {
            setUsernameError('');
        }
    };

    const handlePasswordBlur = () => {
        if (!validatePassword(password)) {
            setPasswordError('5자 이상 20자 이하로 입력해 주세요.(영문/숫자/특수문자)');
        } else {
            setPasswordError('');
        }
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
        setPasswordMatch(e.target.value === password);
    };

    return (
        <Container className="mt-5">
            <Row className="justify-content-md-center">
                <Col md={6}>
                    <h2 className="text-center">계정 등록</h2>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="username">
                            <Form.Label>ID</Form.Label>
                            <Form.Control
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                onBlur={handleUsernameBlur}
                                required
                            />
                            {usernameError && <div className="text-danger">{usernameError}</div>}
                        </Form.Group>

                        <Form.Group controlId="password">
                            <Form.Label>비밀번호</Form.Label>
                            <Form.Control
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onBlur={handlePasswordBlur}
                                required
                            />
                            {passwordError && <div className="text-danger">{passwordError}</div>}
                        </Form.Group>

                        <Form.Group controlId="confirmPassword">
                            <Form.Label>비밀번호 확인</Form.Label>
                            <Form.Control
                                type="password"
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                required
                                style={{
                                    borderColor: passwordMatch ? 'green' : '',
                                }}
                            />
                            {confirmPassword && (
                                <div className={passwordMatch ? 'text-success' : 'text-danger'}>
                                    {passwordMatch ? '비밀번호가 일치합니다.' : '비밀번호가 일치하지 않습니다.'}
                                </div>
                            )}
                        </Form.Group>

                        <Button variant="primary" type="submit" className="w-100 mt-3">
                            Register
                        </Button>

                        <div className="text-center mt-3">
                            <Button variant="link" onClick={() => navigate('/login')}>로그인 페이지로 이동</Button>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default RegisterPage;
