import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchFavoriteChordSheets, updateFavoriteChordSheetSortOrders } from "../services/ChordSheetApiService";
import { Table, Button } from "react-bootstrap";
import { ChordSheet } from "../models/ChordSheet";
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { MultiBackend, TouchTransition, MouseTransition } from 'dnd-multi-backend';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import '../assets/styles/ChordSheetItems.css';
import { SortDirectionType } from '../enums/SortDirectionType'
import { SortFieldType } from '../enums/SortFieldType'

const ItemType = {
    CHORD_SHEET: 'chordSheet',
};

const backendOptions = {
    backends: [
        {
            id: 'html5',
            backend: HTML5Backend,
            transition: MouseTransition,
        },
        {
            id: 'touch',
            backend: TouchBackend,
            options: { enableMouseEvents: true },
            transition: TouchTransition,
        },
    ]
};

const sortChordSheets = (chordSheets: ChordSheet[], field: keyof ChordSheet, direction: SortDirectionType.ASC | SortDirectionType.DESC) => {
    return chordSheets.sort((a, b) => {
        const aValue = a[field.toLowerCase() as keyof ChordSheet];
        const bValue = b[field.toLowerCase() as keyof ChordSheet];

        if (typeof aValue === 'string' && typeof bValue === 'string') {
            if (/^[가-힣]/.test(aValue) && /^[가-힣]/.test(bValue)) {
                return direction === SortDirectionType.ASC ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            } else if (/^[가-힣]/.test(aValue)) {
                return direction === SortDirectionType.ASC ? -1 : 1;
            } else if (/^[가-힣]/.test(bValue)) {
                return direction === SortDirectionType.ASC ? 1 : -1;
            } else {
                return direction === SortDirectionType.ASC ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            }
        }
        return 0;
    });
};

function DraggableRow({ chordSheet, index, moveRow, handleRowClick, checkSortOrderChange }: { chordSheet: ChordSheet, index: number, moveRow: any, handleRowClick: any, checkSortOrderChange: any }) {
    const [{ isDragging }, ref, preview] = useDrag({
        type: ItemType.CHORD_SHEET,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: ItemType.CHORD_SHEET,
        hover: (item: { index: number }) => {
            if (item.index !== index) {
                moveRow(item.index, index);
                item.index = index;
            }
        },
        drop: () => {
            checkSortOrderChange(); // 드롭 완료 시 순서 변경 여부 확인
        },
    });

    const opacity = isDragging ? 0.5 : 1;

    return (
        <tr ref={preview} style={{ opacity }}>
            <td ref={node => drop(ref(node))} style={{ cursor: 'move', textAlign: 'center' }}>☰</td>
            <td className="truncate" onClick={() => handleRowClick(chordSheet.id)}>{chordSheet.title}</td>
            <td className="truncate" onClick={() => handleRowClick(chordSheet.id)}>{chordSheet.artist}</td>
        </tr>
    );
}

function FavoriteChordSheetItems() {
    const [chordSheets, setChordSheets] = useState<ChordSheet[]>([]);
    const [originalSortOrder, setOriginalSortOrder] = useState<number[]>([]);
    const [isSortOrderChanged, setIsSortOrderChanged] = useState<boolean>(false);
    const [sortConfig, setSortConfig] = useState<{ field: keyof ChordSheet; direction: SortDirectionType.ASC | SortDirectionType.DESC } | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        getFavoriteChordSheets().then();
    }, []);

    useEffect(() => {
        checkSortOrderChange();
    }, [chordSheets, sortConfig]);

    const getFavoriteChordSheets = async () => {
        try {
            const favoriteChordSheets = await fetchFavoriteChordSheets();
            setChordSheets(favoriteChordSheets);
            setOriginalSortOrder(favoriteChordSheets.map(sheet => sheet.id)); // 초기 순서 저장
        } catch (error) {
            console.error(`Error fetching favorite chord sheets: ${error}`);
        }
    };

    const handleRowClick = (id: number) => {
        navigate(`/chord-sheets/${id}`);
    };

    const moveRow = (dragIndex: number, hoverIndex: number) => {
        const draggedItem = chordSheets[dragIndex];
        const updatedItems = [...chordSheets];
        updatedItems.splice(dragIndex, 1);
        updatedItems.splice(hoverIndex, 0, draggedItem);
        setChordSheets(updatedItems);
    };

    const checkSortOrderChange = () => {
        const currentSortOrder = chordSheets.map(chordSheet => chordSheet.id);
        const sortOrderChanged = JSON.stringify(currentSortOrder) !== JSON.stringify(originalSortOrder);
        setIsSortOrderChanged(sortOrderChanged);
    };

    const saveSortOrder = async () => {
        try {
            const sortedIds = chordSheets.map(chordSheet => chordSheet.id).reverse();
            await updateFavoriteChordSheetSortOrders(sortedIds);
            setOriginalSortOrder(sortedIds);
            setIsSortOrderChanged(false);
            alert("순서 저장 완료");
        } catch (error) {
            console.error(`Error updating chord sheet sort order: ${error}`);
        }
    };

    const handleSort = (field: SortFieldType) => {
        let direction: SortDirectionType.ASC | SortDirectionType.DESC = SortDirectionType.ASC;
        if (sortConfig && sortConfig.field === field && sortConfig.direction === SortDirectionType.ASC) {
            direction = SortDirectionType.DESC;
        }
        const sortedSheets = sortChordSheets([...chordSheets], field.toLowerCase() as keyof ChordSheet, direction);
        setChordSheets(sortedSheets);
        setSortConfig({ field: field.toLowerCase() as keyof ChordSheet, direction });
    };

    return (
        <DndProvider backend={MultiBackend} options={backendOptions}>
            <div className="container chord-sheet-list">
                {chordSheets.length === 0 && (
                    <div className="row justify-content-center">
                        <div className="col-md-8 text-center">
                            조회된 항목이 없습니다.
                        </div>
                    </div>
                )}
                {chordSheets.length > 0 && (
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th style={{width: '10%'}}><FontAwesomeIcon icon={faSort} /></th>
                                    <th style={{width: '65%'}} onClick={() => handleSort(SortFieldType.TITLE)}>
                                        제목
                                        {sortConfig?.field === SortFieldType.TITLE && (
                                            <FontAwesomeIcon icon={sortConfig.direction === SortDirectionType.ASC ? faSortUp : faSortDown} />
                                        )}
                                    </th>
                                    <th style={{width: '25%'}} onClick={() => handleSort(SortFieldType.ARTIST)}>
                                        가수
                                        {sortConfig?.field === SortFieldType.ARTIST && (
                                            <FontAwesomeIcon icon={sortConfig.direction === SortDirectionType.ASC ? faSortUp : faSortDown} />
                                        )}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {chordSheets.map((chordSheet, index) => (
                                    <DraggableRow
                                        key={chordSheet.id}
                                        chordSheet={chordSheet}
                                        index={index}
                                        moveRow={moveRow}
                                        handleRowClick={handleRowClick}
                                        checkSortOrderChange={checkSortOrderChange}
                                    />
                                ))}
                                </tbody>
                            </Table>
                            {isSortOrderChanged && (
                                <div className="text-center">
                                    <Button variant="primary" onClick={saveSortOrder} title="순서 저장">
                                        <FontAwesomeIcon icon={faSave} />
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </DndProvider>
    );
}

export default FavoriteChordSheetItems;
