import React from "react";
import { useAuth } from "../contexts/AuthContext";
import ChordSheetListPage from "./ChordSheetListPage";
import FavoriteChordSheetListPage from "./FavoriteChordSheetListPage";

const HomePage: React.FC = () => {
    const { isAuthenticated } = useAuth(); // 로그인 상태 확인
    return isAuthenticated ? <FavoriteChordSheetListPage /> : <ChordSheetListPage />;
};

export default HomePage;
