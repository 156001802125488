import React, { useEffect, useRef, useState } from 'react';
import { renderChordSvg } from '../utils/chordSvgUtils';
import { ChordExposureType } from "../enums/ChordExposureType";
import chordSvgMap from '../constants/chordSvgMap';
import { Col, Container, Form, Row, InputGroup, FormControl } from 'react-bootstrap';
import '../assets/styles/ChordLibrary.css';
import { ChordStyle } from "svguitar";

const ChordLibraryPage: React.FC = () => {
    const [selectedChord, setSelectedChord] = useState<string>('');
    const [selectedExposureType, setSelectedExposureType] = useState<ChordExposureType>(ChordExposureType.IMAGE_GUITAR);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [warningMessage, setWarningMessage] = useState<string>('');
    const svgContainerRef = useRef<HTMLSpanElement>(null);

    const chords = Object.keys(chordSvgMap[selectedExposureType]);

    const filteredChords = chords.filter(chord =>
        chord.includes(searchTerm)
    );

    useEffect(() => {
        if (svgContainerRef.current) {
            if (filteredChords.length > 0) {
                renderChordSvg(svgContainerRef.current, selectedExposureType, selectedChord, ChordStyle.handdrawn);
            } else {
                svgContainerRef.current.innerHTML = '';
                setSelectedChord('');
            }
        }
    }, [selectedChord, selectedExposureType]);

    useEffect(() => {
        if (filteredChords.length > 0) {
            setSelectedChord(filteredChords[0]);
        } else {
            setSelectedChord('');
        }
    }, [searchTerm]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        // 필터링: 영문, 숫자, # 만 허용
        const filteredValue = inputValue.replace(/[^A-Za-z0-9#]/g, '');

        if (inputValue !== filteredValue) {
            setWarningMessage('영문, 숫자, # 만 입력 가능합니다.');
        } else {
            setWarningMessage('');
        }

        setSearchTerm(filteredValue);
    };

    return (
        <Container style={{ paddingTop: '20px' }}>
            <Row className="mb-3">
                <Col xs={12}>
                    <InputGroup>
                        <FormControl
                            placeholder="Search Chords"
                            value={searchTerm}
                            onChange={handleInputChange}
                        />
                    </InputGroup>
                    {warningMessage && (
                        <div className="text-danger mt-2">
                            {warningMessage}
                        </div>
                    )}
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={6} className="pr-1">
                    <Form.Group controlId="chord-select">
                        <Form.Control
                            as="select"
                            value={selectedChord}
                            onChange={(e) => setSelectedChord(e.target.value)}
                        >
                            {filteredChords.map((chord) => (
                                <option key={chord} value={chord}>
                                    {chord}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col xs={6} className="pl-1">
                    <Form.Group controlId="exposure-type-select">
                        <Form.Control
                            as="select"
                            value={selectedExposureType}
                            onChange={(e) => setSelectedExposureType(e.target.value as ChordExposureType)}
                        >
                            <option value={ChordExposureType.IMAGE_GUITAR}>Guitar</option>
                            <option value={ChordExposureType.IMAGE_UKULELE}>Ukulele</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col md={6} className="offset-md-3">
                    <span ref={svgContainerRef} className="library-chord d-flex justify-content-center"></span>
                </Col>
            </Row>
        </Container>
    );
};

export default ChordLibraryPage;
