import {ChordExposureType} from "../enums/ChordExposureType";
import {Chord} from "svguitar";

// A
const GTR_A: Chord[] = [{
    fingers: [
        [1, 0],   // 1st string, open
        [2, 2],   // 2nd string, 2nd fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 2],   // 4th string, 2nd fret
        [5, 0],   // 5th string, open
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// A# (Bb)
const GTR_A_SHP: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 3],   // 2nd string, 3rd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 3],   // 4th string, 3rd fret
        [5, 1],   // 5th string, 1st fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// A#6 (Bb6)
const GTR_A_SHP_6: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 3], // 4th string, 3rd fret
        [5, 1], // 5th string, 1st fret
        [6, 1], // 6th string, 1st fret
    ],
    barres: []
}];

// A#7 (Bb7)
const GTR_A_SHP_7: Chord[] = [{
    title: "A#7",
    fingers: [
        [1, 4],   // 1st string, 4th fret
        [2, 3],   // 2nd string, 3rd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 3],   // 4th string, 3rd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: [
        // {
        //     fromString: 4,
        //     toString: 1,
        //     fret: 3
        // }
    ]
}];

// A#9 (Bb9)
const GTR_A_SHP_9: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 5], // 4th string, 5th fret
        [5, 3], // 5th string, 3rd fret
        [6, 3], // 6th string, 3rd fret
    ],
    barres: []
}];

// A#aug (Bbaug)
const GTR_A_SHP_aug: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 3],   // 2nd string, 3rd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 0],   // 4th string, open
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// A#dim (Bbdim)
const GTR_A_SHP_dim: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 2],   // 4th string, 2nd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// A#m (Bbm)
const GTR_A_SHP_m: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 3],   // 4th string, 3rd fret
        [5, 1],   // 5th string, 1st fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// A#m6 (Bbm6)
const GTR_A_SHP_m_6: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 3],   // 4th string, 3rd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// A#m7 (Bbm7)
const GTR_A_SHP_m_7: Chord[] = [{
    fingers: [
        [1, 4],   // 1st string, 4th fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 3],   // 4th string, 3rd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// A#M7 (BbM7)
const GTR_A_SHP_M_7: Chord[] = [{
    fingers: [
        [1, "x"], // 1st string, silent
        [2, 3],   // 2nd string, 3rd fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 3],   // 4th string, 3rd fret
        [5, 1],   // 5th string, 1st fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// A6
const GTR_A_6: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 2],   // 4th string, 2nd fret
        [5, 0],   // 5th string, open
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// A7
const GTR_A_7: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 2],   // 4th string, 2nd fret
        [5, 0],   // 5th string, open
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// A9
const GTR_A_9: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 4],   // 3rd string, 4th fret
        [4, 2],   // 4th string, 2nd fret
        [5, 0],   // 5th string, open
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Aadd9
const GTR_A_add9: Chord[] = [{
    fingers: [
        [1, 0],   // 1st string, open
        [2, 2],   // 2nd string, 2nd fret
        [3, 4],   // 3rd string, 4th fret
        [4, 2],   // 4th string, 2nd fret
        [5, 0],   // 5th string, open
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Aaug
const GTR_A_aug: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 3],   // 4th string, 3rd fret
        [5, 0],   // 5th string, open
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Ab (G#)
const GTR_A_b: Chord[] = [{
    fingers: [
        [1, 1], // [1, 4], // 1st string, 4th fret
        [2, 1], // [2, 4], // 2nd string, 4th fret
        [3, 2], // [3, 5], // 3rd string, 5th fret
        [4, 3], // [4, 6], // 4th string, 6th fret
        [5, 3], // [5, 6], // 5th string, 6th fret
        [6, 1], // [6, 4], // 6th string, 4th fret
    ],
    barres: [],
    position: 4
}];

// Ab6 (G#6)
const GTR_A_b_6: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 1], // 4th string, 1st fret
        [5, 3], // 5th string, 3rd fret
        [6, 4], // 6th string, 4th fret
    ],
    barres: []
}];

// Ab7 (G#7)
const GTR_A_b_7: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 1],   // 3rd string, 1st fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Ab9 (G#9)
const GTR_A_b_9: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Abaug (G#aug)
const GTR_A_b_aug: Chord[] = [{
    fingers: [
        [1, 0],   // 1st string, open
        [2, 1],   // 2nd string, 1st fret
        [3, 1],   // 3rd string, 1st fret
        [4, 2],   // 4th string, 2nd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Abdim (G#dim)
const GTR_A_b_dim: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 0],   // 2nd string, open
        [3, 1],   // 3rd string, 1st fret
        [4, 0],   // 4th string, open
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Abm (G#m)
const GTR_A_b_m: Chord[] = [{
    fingers: [
        [1, 1], // [1, 4], // 1st string, 4th fret
        [2, 1], // [2, 4], // 2nd string, 4th fret
        [3, 1], // [3, 4], // 3rd string, 4th fret
        [4, 3], // [4, 6], // 4th string, 6th fret
        [5, 3], // [5, 6], // 5th string, 6th fret
        [6, 1], // [6, 4], // 6th string, 4th fret
    ],
    barres: [],
    position: 4
}];

// Abm6 (G#m6)
const GTR_A_b_m_6: Chord[] = [{
    fingers: [
        [1, 4],   // 1st string, 4th fret
        [2, 4],   // 2nd string, 4th fret
        [3, 4],   // 3rd string, 4th fret
        [4, "x"], // 4th string, silent
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Abm7 (G#m7)
const GTR_A_b_m_7: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 0],   // 2nd string, open
        [3, 1],   // 3rd string, 1st fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// AbM7 (G#M7)
const GTR_A_b_M_7: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 1],   // 3rd string, 1st fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Adim
const GTR_A_dim: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Am
const GTR_A_m: Chord[] = [{
    fingers: [
        [1, 0],   // 1st string, open
        [2, 1],   // 2nd string, 1st fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 2],   // 4th string, 2nd fret
        [5, 0],   // 5th string, open
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Am6
const GTR_A_m_6: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 2],   // 4th string, 2nd fret
        [5, 0],   // 5th string, open
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Am7
const GTR_A_m_7: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 2],   // 4th string, 2nd fret
        [5, 0],   // 5th string, open
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// AmM7
const GTR_A_m_M_7: Chord[] = [{
    fingers: [
        [1, 0],   // 1st string, open
        [2, 1],   // 2nd string, 1st fret
        [3, 1],   // 3rd string, 1st fret
        [4, 2],   // 4th string, 2nd fret
        [5, 0],   // 5th string, open
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// AM7
const GTR_A_M_7: Chord[] = [{
    fingers: [
        [1, 0],   // 1st string, open
        [2, 2],   // 2nd string, 2nd fret
        [3, 1],   // 3rd string, 1st fret
        [4, 2],   // 4th string, 2nd fret
        [5, 0],   // 5th string, open
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Asus4
const GTR_A_sus4: Chord[] = [{
    fingers: [
        [1, 0],   // 1st string, open
        [2, 3],   // 2nd string, 3rd fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 2],   // 4th string, 2nd fret
        [5, 0],   // 5th string, open
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// B
const GTR_B: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 4],   // 2nd string, 4th fret
        [3, 4],   // 3rd string, 4th fret
        [4, 4],   // 4th string, 4th fret
        [5, 2],   // 5th string, 2nd fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// B6
const GTR_B_6: Chord[] = [{
    fingers: [
        [1, 4], // 1st string, 4th fret
        [2, 4], // 2nd string, 4th fret
        [3, 4], // 3rd string, 4th fret
        [4, 4], // 4th string, 4th fret
        [5, 2], // 5th string, 2nd fret
        [6, 2], // 6th string, 2nd fret
    ],
    barres: []
}];

// B7
const GTR_B_7: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 0],   // 2nd string, open
        [3, 2],   // 3rd string, 2nd fret
        [4, 1],   // 4th string, 1st fret
        [5, 2],   // 5th string, 2nd fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// B9
const GTR_B_9: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 1],   // 4th string, 1st fret
        [5, 2],   // 5th string, 2nd fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Badd9
const GTR_B_add9: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 4],   // 2nd string, 4th fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 4],   // 4th string, 4th fret
        [5, 2],   // 5th string, 2nd fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Baug
const GTR_B_aug: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 4],   // 2nd string, 4th fret
        [3, 4],   // 3rd string, 4th fret
        [4, 5],   // 4th string, 5th fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Bb (A#)
const GTR_B_b: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 3],   // 2nd string, 3rd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 3],   // 4th string, 3rd fret
        [5, 1],   // 5th string, 1st fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Bb6 (A#6)
const GTR_B_b_6: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 3], // 4th string, 3rd fret
        [5, 1], // 5th string, 1st fret
        [6, 1], // 6th string, 1st fret
    ],
    barres: []
}];

// Bb7 (A#7)
const GTR_B_b_7: Chord[] = [{
    fingers: [
        [1, 4],   // 1st string, 4th fret
        [2, 3],   // 2nd string, 3rd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 3],   // 4th string, 3rd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Bb9 (A#9)
const GTR_B_b_9: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 5], // 4th string, 5th fret
        [5, 3], // 5th string, 3rd fret
        [6, 3], // 6th string, 3rd fret
    ],
    barres: []
}];

// Bbaug (A#aug)
const GTR_B_b_aug: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 3],   // 2nd string, 3rd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 0],   // 4th string, open
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Bbdim (A#dim)
const GTR_B_b_dim: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 2],   // 4th string, 2nd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Bbm (A#m)
const GTR_B_b_m: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 3],   // 4th string, 3rd fret
        [5, 1],   // 5th string, 1st fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Bbm6 (A#m6)
const GTR_B_b_m_6: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 3],   // 4th string, 3rd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Bbm7 (A#m7)
const GTR_B_b_m_7: Chord[] = [{
    fingers: [
        [1, 4],   // 1st string, 4th fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 3],   // 4th string, 3rd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// BbM7 (A#M7)
const GTR_B_b_M_7: Chord[] = [{
    fingers: [
        [1, "x"], // 1st string, silent
        [2, 3],   // 2nd string, 3rd fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 3],   // 4th string, 3rd fret
        [5, 1],   // 5th string, 1st fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Bdim
const GTR_B_dim: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 0],   // 2nd string, open
        [3, 1],   // 3rd string, 1st fret
        [4, 0],   // 4th string, open
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Bm
const GTR_B_m: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 3],   // 2nd string, 3rd fret
        [3, 4],   // 3rd string, 4th fret
        [4, 4],   // 4th string, 4th fret
        [5, 2],   // 5th string, 2nd fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Bm6
const GTR_B_m_6: Chord[] = [{
    fingers: [
        [1, 4],   // 1st string, 4th fret
        [2, 3],   // 2nd string, 3rd fret
        [3, 4],   // 3rd string, 4th fret
        [4, 4],   // 4th string, 4th fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Bm7
const GTR_B_m_7: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 3],   // 2nd string, 3rd fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 4],   // 4th string, 4th fret
        [5, 2],   // 5th string, 2nd fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// BmM7
const GTR_B_m_M_7: Chord[] = [{
    fingers: [
        [1, 2],  // 1st string, 2nd fret
        [2, 3],  // 2nd string, 3rd fret
        [3, 3],  // 3rd string, 3rd fret
        [4, 4],  // 4th string, 4th fret
        [5, 2],  // 5th string, 2nd fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// BM7
const GTR_B_M_7: Chord[] = [{
    fingers: [
        [1, "x"], // 1st string, silent
        [2, 4],   // 2nd string, 4th fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 4],   // 4th string, 4th fret
        [5, 2],   // 5th string, 2nd fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Bsus4
const GTR_B_sus4: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 5],   // 2nd string, 5th fret
        [3, 4],   // 3rd string, 4th fret
        [4, 4],   // 4th string, 4th fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// C
const GTR_C: Chord[] = [{
    fingers: [
        [1, 0],   // 1st string, open
        [2, 1],   // 2nd string, 1st fret
        [3, 0],   // 3rd string, open
        [4, 2],   // 4th string, 2nd fret
        [5, 3],   // 5th string, 3rd fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// C# (Db)
const GTR_C_SHP: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 1],   // 3rd string, 1st fret
        [4, 3],   // 4th string, 3rd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// C#6 (Db6)
const GTR_C_SHP_6: Chord[] = [{
    fingers: [
        [1, 4],   // 1st string, 4th fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 3],   // 4th string, 3rd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// C#7 (Db7)
const GTR_C_SHP_7: Chord[] = [{
    fingers: [
        [1, 4],   // 1st string, 4th fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 4],   // 3rd string, 4th fret
        [4, 3],   // 4th string, 3rd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// C#9 (Db9)
const GTR_C_SHP_9: Chord[] = [{
    fingers: [
        [1, 4],   // 1st string, 4th fret
        [2, 4],   // 2nd string, 4th fret
        [3, 4],   // 3rd string, 4th fret
        [4, 3],   // 4th string, 3rd fret
        [5, 4],   // 5th string, 4th fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// C#aug (Dbaug)
const GTR_C_SHP_aug: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 3],   // 4th string, 3rd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// C#dim (Dbdim)
const GTR_C_SHP_dim: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 3],   // 4th string, 3rd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// C#m (Dbm)
const GTR_C_SHP_m: Chord[] = [{
    fingers: [
        [1, 0],   // 1st string, open
        [2, 2],   // 2nd string, 2nd fret
        [3, 1],   // 3rd string, 1st fret
        [4, 2],   // 4th string, 2nd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// C#m6 (Dbm6)
const GTR_C_SHP_m_6: Chord[] = [{
    fingers: [
        [1, 4],   // 1st string, 4th fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 2],   // 4th string, 2nd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// C#m7 (Dbm7)
const GTR_C_SHP_m_7: Chord[] = [{
    fingers: [
        [1, 4],   // 1st string, 4th fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 4],   // 3rd string, 4th fret
        [4, 2],   // 4th string, 2nd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// C#M7 (DbM7)
const GTR_C_SHP_M_7: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 1],   // 2nd string, 1st fret
        [3, 1],   // 3rd string, 1st fret
        [4, 3],   // 4th string, 3rd fret
        [5, 4],   // 5th string, 4th fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// C6
const GTR_C_6: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 2],   // 4th string, 2nd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// C7
const GTR_C_7: Chord[] = [{
    fingers: [
        [1, 0],   // 1st string, open
        [2, 1],   // 2nd string, 1st fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 2],   // 4th string, 2nd fret
        [5, 3],   // 5th string, 3rd fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// C9
const GTR_C_9: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 3],   // 2nd string, 3rd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 2],   // 4th string, 2nd fret
        [5, 3],   // 5th string, 3rd fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Cadd9
const GTR_C_add9: Chord[] = [{
    fingers: [
        [1, 0],   // 1st string, open
        [2, 3],   // 2nd string, 3rd fret
        [3, 0],   // 3rd string, open
        [4, 2],   // 4th string, 2nd fret
        [5, 3],   // 5th string, 3rd fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Caug
const GTR_C_aug: Chord[] = [{
    fingers: [
        [1, 0],   // 1st string, open
        [2, 1],   // 2nd string, 1st fret
        [3, 1],   // 3rd string, 1st fret
        [4, 2],   // 4th string, 2nd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Cdim
const GTR_C_dim: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Cm
const GTR_C_m: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 4],   // 2nd string, 4th fret
        [3, 5],   // 3rd string, 5th fret
        [4, 5],   // 4th string, 5th fret
        [5, 3],   // 5th string, 3rd fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Cm6
const GTR_C_m_6: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Cm7
const GTR_C_m_7: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// CmM7
const GTR_C_m_M_7: Chord[] = [{
    fingers: [
        [1, 3],  // 1st string, 3rd fret
        [2, 4],  // 2nd string, 4th fret
        [3, 3],  // 3rd string, 3rd fret
        [4, 5],  // 4th string, 5th fret
        [5, 3],  // 5th string, 3rd fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// CM7
const GTR_C_M_7: Chord[] = [{
    fingers: [
        [1, 0],   // 1st string, open
        [2, 0],   // 2nd string, open
        [3, 0],   // 3rd string, open
        [4, 2],   // 4th string, 2nd fret
        [5, 3],   // 5th string, 3rd fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Csus4
const GTR_C_sus4: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 1],   // 2nd string, 1st fret
        [3, 0],   // 3rd string, open
        [4, 2],   // 4th string, 2nd fret
        [5, 3],   // 5th string, 3rd fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// D
const GTR_D: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 3],   // 2nd string, 3rd fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 0],   // 4th string, open
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// D# (Eb)
const GTR_D_SHP: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 4],   // 2nd string, 4th fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 5],   // 4th string, 5th fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// D#6 (Eb6)
const GTR_D_SHP_6: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// D#7 (Eb7)
const GTR_D_SHP_7: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// D#9 (Eb9)
const GTR_D_SHP_9: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 1], // 4th string, 1st fret
        [5, 1], // 5th string, 1st fret
        [6, 1], // 6th string, 1st fret
    ],
    barres: []
}];

// D#aug (Ebaug)
const GTR_D_SHP_aug: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 0],   // 2nd string, open
        [3, 0],   // 3rd string, open
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// D#dim (Ebdim)
const GTR_D_SHP_dim: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// D#m (Ebm)
const GTR_D_SHP_m: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 4],   // 2nd string, 4th fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 4],   // 4th string, 4th fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// D#m6 (Ebm6)
const GTR_D_SHP_m_6: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// D#m7 (Ebm7)
const GTR_D_SHP_m_7: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// D#M7 (EbM7)
const GTR_D_SHP_M_7: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 3],   // 2nd string, 3rd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// D6
const GTR_D_6: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 0],   // 2nd string, open
        [3, 2],   // 3rd string, 2nd fret
        [4, 0],   // 4th string, open
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// D7
const GTR_D_7: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 0],   // 4th string, open
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// D9
const GTR_D_9: Chord[] = [{
    fingers: [
        [1, 0],   // 1st string, open
        [2, 1],   // 2nd string, 1st fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 0],   // 4th string, open
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Dadd9
const GTR_D_add9: Chord[] = [{
    fingers: [
        [1, 0],   // 1st string, open
        [2, 3],   // 2nd string, 3rd fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 0],   // 4th string, open
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Daug
const GTR_D_aug: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 3],   // 2nd string, 3rd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 0],   // 4th string, open
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Db (C#)
const GTR_D_b: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 1],   // 3rd string, 1st fret
        [4, 3],   // 4th string, 3rd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Db6 (C#6)
const GTR_D_b_6: Chord[] = [{
    fingers: [
        [1, 4],   // 1st string, 4th fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 3],   // 4th string, 3rd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Db7 (C#7)
const GTR_D_b_7: Chord[] = [{
    fingers: [
        [1, 4],   // 1st string, 4th fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 4],   // 3rd string, 4th fret
        [4, 3],   // 4th string, 3rd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Db9 (C#9)
const GTR_D_b_9: Chord[] = [{
    fingers: [
        [1, 4],   // 1st string, 4th fret
        [2, 4],   // 2nd string, 4th fret
        [3, 4],   // 3rd string, 4th fret
        [4, 3],   // 4th string, 3rd fret
        [5, 4],   // 5th string, 4th fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Dbaug (C#aug)
const GTR_D_b_aug: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 3],   // 4th string, 3rd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Dbdim (C#dim)
const GTR_D_b_dim: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 3],   // 4th string, 3rd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Dbm (C#m)
const GTR_D_b_m: Chord[] = [{
    fingers: [
        [1, 0],   // 1st string, open
        [2, 2],   // 2nd string, 2nd fret
        [3, 1],   // 3rd string, 1st fret
        [4, 2],   // 4th string, 2nd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Dbm6 (C#m6)
const GTR_D_b_m_6: Chord[] = [{
    fingers: [
        [1, 4],   // 1st string, 4th fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 2],   // 4th string, 2nd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Dbm7 (C#m7)
const GTR_D_b_m_7: Chord[] = [{
    fingers: [
        [1, 4],   // 1st string, 4th fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 4],   // 3rd string, 4th fret
        [4, 2],   // 4th string, 2nd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// DbM7 (C#M7)
const GTR_D_b_M_7: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 1],   // 2nd string, 1st fret
        [3, 1],   // 3rd string, 1st fret
        [4, 3],   // 4th string, 3rd fret
        [5, 4],   // 5th string, 4th fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Ddim
const GTR_D_dim: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 0],   // 2nd string, open
        [3, 1],   // 3rd string, 1st fret
        [4, 0],   // 4th string, open
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Dm
const GTR_D_m: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 3],   // 2nd string, 3rd fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 0],   // 4th string, open
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Dm6
const GTR_D_m_6: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 0],   // 2nd string, open
        [3, 2],   // 3rd string, 2nd fret
        [4, 0],   // 4th string, open
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Dm7
const GTR_D_m_7: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 1],   // 2nd string, 1st fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 0],   // 4th string, open
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// DmM7
const GTR_D_m_M_7: Chord[] = [{
    fingers: [
        [1, 1],  // 1st string, 1st fret
        [2, 2],  // 2nd string, 2nd fret
        [3, 2],  // 3rd string, 2nd fret
        [4, 0],  // 4th string, open
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// DM7
const GTR_D_M_7: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 0],   // 4th string, open
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Dsus4
const GTR_D_sus4: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 3],   // 2nd string, 3rd fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 0],   // 4th string, open
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// E
const GTR_E: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 0], // 2nd string, open
        [3, 1], // 3rd string, 1st fret
        [4, 2], // 4th string, 2nd fret
        [5, 2], // 5th string, 2nd fret
        [6, 0], // 6th string, open
    ],
    barres: []
}];

// E6
const GTR_E_6: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 2], // 2nd string, 2nd fret
        [3, 1], // 3rd string, 1st fret
        [4, 2], // 4th string, 2nd fret
        [5, 2], // 5th string, 2nd fret
        [6, 0], // 6th string, open
    ],
    barres: []
}];

// E7
const GTR_E_7: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 3], // 2nd string, 3rd fret
        [3, 1], // 3rd string, 1st fret
        [4, 2], // 4th string, 2nd fret
        [5, 2], // 5th string, 2nd fret
        [6, 0], // 6th string, open
    ],
    barres: []
}];

// E9
const GTR_E_9: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 0], // 2nd string, open
        [3, 1], // 3rd string, 1st fret
        [4, 0], // 4th string, open
        [5, 2], // 5th string, 2nd fret
        [6, 0], // 6th string, open
    ],
    barres: []
}];

// Eadd9
const GTR_E_add9: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 0], // 2nd string, open
        [3, 1], // 3rd string, 1st fret
        [4, 2], // 4th string, 2nd fret
        [5, 2], // 5th string, 2nd fret
        [6, 0], // 6th string, open
    ],
    barres: []
}];

// Eaug
const GTR_E_aug: Chord[] = [{
    fingers: [
        [1, 0],   // 1st string, open
        [2, 1],   // 2nd string, 1st fret
        [3, 1],   // 3rd string, 1st fret
        [4, 2],   // 4th string, 2nd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Eb (D#)
const GTR_E_b: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 4],   // 2nd string, 4th fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 5],   // 4th string, 5th fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Eb6 (D#6)
const GTR_E_b_6: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Eb7 (D#7)
const GTR_E_b_7: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Eb9 (D#9)
const GTR_E_b_9: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 1], // 4th string, 1st fret
        [5, 1], // 5th string, 1st fret
        [6, 1], // 6th string, 1st fret
    ],
    barres: []
}];

// Ebaug (D#aug)
const GTR_E_b_aug: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 0],   // 2nd string, open
        [3, 0],   // 3rd string, open
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Ebdim (D#dim)
const GTR_E_b_dim: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Ebm (D#m)
const GTR_E_b_m: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 4],   // 2nd string, 4th fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 4],   // 4th string, 4th fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Ebm6 (D#m6)
const GTR_E_b_m_6: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Ebm7 (D#m7)
const GTR_E_b_m_7: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// EbM7 (D#M7)
const GTR_E_b_M_7: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 3],   // 2nd string, 3rd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Edim
const GTR_E_dim: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 2],   // 4th string, 2nd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Em
const GTR_E_m: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 0], // 2nd string, open
        [3, 0], // 3rd string, open
        [4, 2], // 4th string, 2nd fret
        [5, 2], // 5th string, 2nd fret
        [6, 0], // 6th string, open
    ],
    barres: []
}];

// Em6
const GTR_E_m_6: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 2], // 2nd string, 2nd fret
        [3, 0], // 3rd string, open
        [4, 2], // 4th string, 2nd fret
        [5, 2], // 5th string, 2nd fret
        [6, 0], // 6th string, open
    ],
    barres: []
}];

// Em7
const GTR_E_m_7: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 0], // 2nd string, open
        [3, 0], // 3rd string, open
        [4, 0], // 4th string, open
        [5, 2], // 5th string, 2nd fret
        [6, 0], // 6th string, open
    ],
    barres: []
}];

// EmM7
const GTR_E_m_M_7: Chord[] = [{
    fingers: [
        [1, 0],  // 1st string, open
        [2, 0],  // 2nd string, open
        [3, 0],  // 3rd string, open
        [4, 1],  // 4th string, 1st fret
        [5, 2],  // 5th string, 2nd fret
        [6, 0],  // 6th string, open
    ],
    barres: []
}];

// EM7
const GTR_E_M_7: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 0], // 2nd string, open
        [3, 1], // 3rd string, 1st fret
        [4, 1], // 4th string, 1st fret
        [5, 2], // 5th string, 2nd fret
        [6, 0], // 6th string, open
    ],
    barres: []
}];

// Esus4
const GTR_E_sus4: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 0], // 2nd string, open
        [3, 2], // 3rd string, 2nd fret
        [4, 2], // 4th string, 2nd fret
        [5, 2], // 5th string, 2nd fret
        [6, 0], // 6th string, open
    ],
    barres: []
}];

// F
const GTR_F: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 3], // 4th string, 3rd fret
        [5, 3], // 5th string, 3rd fret
        [6, 1], // 6th string, 1st fret
    ],
    barres: []
}];

// F# (Gb)
const GTR_F_SHP: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 4], // 4th string, 4th fret
        [5, 4], // 5th string, 4th fret
        [6, 2], // 6th string, 2nd fret
    ],
    barres: []
}];

// F#6 (Gb6)
const GTR_F_SHP_6: Chord[] = [{
    fingers: [
        [1, "x"], // 1st string, silent
        [2, 4],   // 2nd string, 4th fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 4],   // 4th string, 4th fret
        [5, 4],   // 5th string, 4th fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// F#7 (Gb7)
const GTR_F_SHP_7: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 2], // 4th string, 2nd fret
        [5, 4], // 5th string, 4th fret
        [6, 2], // 6th string, 2nd fret
    ],
    barres: []
}];

// F#9 (Gb9)
const GTR_F_SHP_9: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 3],   // 2nd string, 3rd fret
        [3, 1],   // 3rd string, 1st fret
        [4, 2],   // 4th string, 2nd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// F#aug (Gbaug)
const GTR_F_SHP_aug: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 3],   // 2nd string, 3rd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 4],   // 4th string, 4th fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// F#dim (Gbdim)
const GTR_F_SHP_dim: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// F#m (Gbm)
const GTR_F_SHP_m: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 4], // 4th string, 4th fret
        [5, 4], // 5th string, 4th fret
        [6, 2], // 6th string, 2nd fret
    ],
    barres: []
}];

// F#m6 (Gbm6)
const GTR_F_SHP_m_6: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// F#m7 (Gbm7)
const GTR_F_SHP_m_7: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 2], // 4th string, 2nd fret
        [5, 4], // 5th string, 4th fret
        [6, 2], // 6th string, 2nd fret
    ],
    barres: []
}];

// F#M7 (GbM7)
const GTR_F_SHP_M_7: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 4],   // 4th string, 4th fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// F6
const GTR_F_6: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 1],   // 2nd string, 1st fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 0],   // 4th string, open
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// F7
const GTR_F_7: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 1], // 4th string, 1st fret
        [5, 3], // 5th string, 3rd fret
        [6, 1], // 6th string, 1st fret
    ],
    barres: []
}];

// F9
const GTR_F_9: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 4],   // 2nd string, 4th fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 3],   // 4th string, 3rd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Fadd9
const GTR_F_add9: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 3],   // 4th string, 3rd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Faug
const GTR_F_aug: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 3],   // 4th string, 3rd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Fdim
const GTR_F_dim: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 0],   // 2nd string, open
        [3, 1],   // 3rd string, 1st fret
        [4, 0],   // 4th string, open
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Fm
const GTR_F_m: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 3], // 4th string, 3rd fret
        [5, 3], // 5th string, 3rd fret
        [6, 1], // 6th string, 1st fret
    ],
    barres: []
}];

// Fm6
const GTR_F_m_6: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 1],   // 2nd string, 1st fret
        [3, 1],   // 3rd string, 1st fret
        [4, 0],   // 4th string, open
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Fm7
const GTR_F_m_7: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 1], // 4th string, 1st fret
        [5, 3], // 5th string, 3rd fret
        [6, 1], // 6th string, 1st fret
    ],
    barres: []
}];

// FmM7
const GTR_F_m_M_7: Chord[] = [{
    fingers: [
        [1, 1],  // 1st string, 1st fret
        [2, 1],  // 2nd string, 1st fret
        [3, 1],  // 3rd string, 1st fret
        [4, 2],  // 4th string, 2nd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// FM7
const GTR_F_M_7: Chord[] = [{
    fingers: [
        [1, 0],   // 1st string, open
        [2, 1],   // 2nd string, 1st fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 3],   // 4th string, 3rd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}, {
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 2], // 4th string, 2nd fret
        [5, 3], // 5th string, 3rd fret
        [6, 1], // 6th string, 1st fret
    ],
    barres: []
}];

// Fsus4
const GTR_F_sus4: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 1],   // 2nd string, 1st fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 3],   // 4th string, 3rd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// G
const GTR_G: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 0], // 2nd string, open
        [3, 0], // 3rd string, open
        [4, 0], // 4th string, open
        [5, 2], // 5th string, 2nd fret
        [6, 3], // 6th string, 3rd fret
    ],
    barres: []
}];

// G# (Ab)
const GTR_G_SHP: Chord[] = [{
    fingers: [
        [1, 1], // [1, 4], // 1st string, 4th fret
        [2, 1], // [2, 4], // 2nd string, 4th fret
        [3, 2], // [3, 5], // 3rd string, 5th fret
        [4, 3], // [4, 6], // 4th string, 6th fret
        [5, 3], // [5, 6], // 5th string, 6th fret
        [6, 1], // [6, 4], // 6th string, 4th fret
    ],
    barres: [],
    position: 4
}];

// G#6 (Ab6)
const GTR_G_SHP_6: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 1], // 4th string, 1st fret
        [5, 3], // 5th string, 3rd fret
        [6, 4], // 6th string, 4th fret
    ],
    barres: []
}];

// G#7 (Ab7)
const GTR_G_SHP_7: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 1],   // 3rd string, 1st fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// G#9 (Ab9)
const GTR_G_SHP_9: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// G#aug (Abaug)
const GTR_G_SHP_aug: Chord[] = [{
    fingers: [
        [1, 0],   // 1st string, open
        [2, 1],   // 2nd string, 1st fret
        [3, 1],   // 3rd string, 1st fret
        [4, 2],   // 4th string, 2nd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// G#dim (Abdim)
const GTR_G_SHP_dim: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 0],   // 2nd string, open
        [3, 1],   // 3rd string, 1st fret
        [4, 0],   // 4th string, open
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// G#m (Abm)
const GTR_G_SHP_m: Chord[] = [{
    fingers: [
        [1, 1], // [1, 4], // 1st string, 4th fret
        [2, 1], // [2, 4], // 2nd string, 4th fret
        [3, 1], // [3, 4], // 3rd string, 4th fret
        [4, 3], // [4, 6], // 4th string, 6th fret
        [5, 3], // [5, 6], // 5th string, 6th fret
        [6, 1], // [6, 4], // 6th string, 4th fret
    ],
    barres: [],
    position: 4
}];

// G#m6 (Abm6)
const GTR_G_SHP_m_6: Chord[] = [{
    fingers: [
        [1, 4],   // 1st string, 4th fret
        [2, 4],   // 2nd string, 4th fret
        [3, 4],   // 3rd string, 4th fret
        [4, "x"], // 4th string, silent
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// G#m7 (Abm7)
const GTR_G_SHP_m_7: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 0],   // 2nd string, open
        [3, 1],   // 3rd string, 1st fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// G#M7 (AbM7)
const GTR_G_SHP_M_7: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 1],   // 3rd string, 1st fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// G6
const GTR_G_6: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 0], // 2nd string, open
        [3, 0], // 3rd string, open
        [4, 0], // 4th string, open
        [5, 2], // 5th string, 2nd fret
        [6, 3], // 6th string, 3rd fret
    ],
    barres: []
}];

// G7
const GTR_G_7: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 0], // 2nd string, open
        [3, 0], // 3rd string, open
        [4, 0], // 4th string, open
        [5, 2], // 5th string, 2nd fret
        [6, 3], // 6th string, 3rd fret
    ],
    barres: []
}];

// G9
const GTR_G_9: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 0], // 2nd string, open
        [3, 2], // 3rd string, 2nd fret
        [4, 0], // 4th string, open
        [5, 0], // 5th string, open
        [6, 3], // 6th string, 3rd fret
    ],
    barres: []
}];

// Gadd9
const GTR_G_add9: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 0], // 2nd string, open
        [3, 2], // 3rd string, 2nd fret
        [4, 0], // 4th string, open
        [5, 0], // 5th string, open
        [6, 3], // 6th string, 3rd fret
    ],
    barres: []
}];

// Gaug
const GTR_G_aug: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 0], // 2nd string, open
        [3, 0], // 3rd string, open
        [4, 1], // 4th string, 1st fret
        [5, 2], // 5th string, 2nd fret
        [6, 3], // 6th string, 3rd fret
    ],
    barres: []
}];

// Gb (F#)
const GTR_G_b: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 4], // 4th string, 4th fret
        [5, 4], // 5th string, 4th fret
        [6, 2], // 6th string, 2nd fret
    ],
    barres: []
}];

// Gb6 (F#6)
const GTR_G_b_6: Chord[] = [{
    fingers: [
        [1, "x"], // 1st string, silent
        [2, 4],   // 2nd string, 4th fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 4],   // 4th string, 4th fret
        [5, 4],   // 5th string, 4th fret
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Gb7 (F#7)
const GTR_G_b_7: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 2], // 4th string, 2nd fret
        [5, 4], // 5th string, 4th fret
        [6, 2], // 6th string, 2nd fret
    ],
    barres: []
}];

// Gb9 (F#9)
const GTR_G_b_9: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 3],   // 2nd string, 3rd fret
        [3, 1],   // 3rd string, 1st fret
        [4, 2],   // 4th string, 2nd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Gbaug (F#aug)
const GTR_G_b_aug: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 3],   // 2nd string, 3rd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 4],   // 4th string, 4th fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Gbdim (F#dim)
const GTR_G_b_dim: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 1],   // 2nd string, 1st fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Gbm (F#m)
const GTR_G_b_m: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 4], // 4th string, 4th fret
        [5, 4], // 5th string, 4th fret
        [6, 2], // 6th string, 2nd fret
    ],
    barres: []
}];

// Gbm6 (F#m6)
const GTR_G_b_m_6: Chord[] = [{
    fingers: [
        [1, 2],   // 1st string, 2nd fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 2],   // 3rd string, 2nd fret
        [4, 1],   // 4th string, 1st fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Gbm7 (F#m7)
const GTR_G_b_m_7: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 2], // 4th string, 2nd fret
        [5, 4], // 5th string, 4th fret
        [6, 2], // 6th string, 2nd fret
    ],
    barres: []
}];

// GbM7 (F#M7)
const GTR_G_b_M_7: Chord[] = [{
    fingers: [
        [1, 1],   // 1st string, 1st fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 4],   // 4th string, 4th fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Gdim
const GTR_G_dim: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 2],   // 2nd string, 2nd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 2],   // 4th string, 2nd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Gm
const GTR_G_m: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 5], // 4th string, 5th fret
        [5, 5], // 5th string, 5th fret
        [6, 3], // 6th string, 3rd fret
    ],
    barres: []
}];

// Gm6
const GTR_G_m_6: Chord[] = [{
    fingers: [
        [1, 3],   // 1st string, 3rd fret
        [2, 3],   // 2nd string, 3rd fret
        [3, 3],   // 3rd string, 3rd fret
        [4, 2],   // 4th string, 2nd fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// Gm7
const GTR_G_m_7: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 3], // 4th string, 3rd fret
        [5, 5], // 5th string, 5th fret
        [6, 3], // 6th string, 3rd fret
    ],
    barres: []
}];

// GmM7
const GTR_G_m_M_7: Chord[] = [{
    fingers: [
        [1, 3],  // 1st string, 3rd fret
        [2, 3],  // 2nd string, 3rd fret
        [3, 3],  // 3rd string, 3rd fret
        [4, 4],  // 4th string, 4th fret
        [5, "x"], // 5th string, silent
        [6, "x"], // 6th string, silent
    ],
    barres: []
}];

// GM7
const GTR_G_M_7: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 0], // 2nd string, open
        [3, 0], // 3rd string, open
        [4, 0], // 4th string, open
        [5, 2], // 5th string, 2nd fret
        [6, 3], // 6th string, 3rd fret
    ],
    barres: []
}];

// Gsus4
const GTR_G_sus4: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 1], // 2nd string, 1st fret
        [3, 0], // 3rd string, open
        [4, 0], // 4th string, open
        [5, 2], // 5th string, 2nd fret
        [6, 3], // 6th string, 3rd fret
    ],
    barres: []
}];

// A
const UKL_A: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 0], // 2nd string, open
        [3, 1], // 3rd string, 1st fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// A# (Bb)
const UKL_A_SHP: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// A#6 (Bb6)
const UKL_A_SHP_6: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// A#7 (Bb7)
const UKL_A_SHP_7: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// A#9 (Bb9)
const UKL_A_SHP_9: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// A#aug (Bbaug)
const UKL_A_SHP_aug: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 2], // 2nd string, 2nd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// A#dim (Bbdim)
const UKL_A_SHP_dim: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 0], // 2nd string, open
        [3, 1], // 3rd string, 1st fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// A#m (Bbm)
const UKL_A_SHP_m: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// A#m6 (Bbm6)
const UKL_A_SHP_m_6: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 3], // 2nd string, 3rd fret
        [3, 1], // 3rd string, 1st fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// A#m7 (Bbm7)
const UKL_A_SHP_m_7: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// A#M7 (BbM7)
const UKL_A_SHP_M_7: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// A6
const UKL_A_6: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 2], // 2nd string, 2nd fret
        [3, 1], // 3rd string, 1st fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// A7
const UKL_A_7: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 0], // 2nd string, open
        [3, 1], // 3rd string, 1st fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// A9
const UKL_A_9: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 0], // 2nd string, open
        [3, 1], // 3rd string, 1st fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Aadd9
const UKL_A_add9: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 0], // 2nd string, open
        [3, 1], // 3rd string, 1st fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// Aaug
const UKL_A_aug: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// Ab (G#)
const UKL_A_b: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 4], // 2nd string, 4th fret
        [3, 3], // 3rd string, 3rd fret
        [4, 5], // 4th string, 5th fret
    ],
    barres: []
}];

// Ab6 (G#6)
const UKL_A_b_6: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 1], // 2nd string, 1st fret
        [3, 3], // 3rd string, 3rd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Ab7 (G#7)
const UKL_A_b_7: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Ab9 (G#9)
const UKL_A_b_9: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// Abaug (G#aug)
const UKL_A_b_aug: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 0], // 2nd string, open
        [3, 0], // 3rd string, open
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Abdim (G#dim)
const UKL_A_b_dim: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Abm (G#m)
const UKL_A_b_m: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 4], // 2nd string, 4th fret
        [3, 3], // 3rd string, 3rd fret
        [4, 4], // 4th string, 4th fret
    ],
    barres: []
}];

// Abm6 (G#m6)
const UKL_A_b_m_6: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 1], // 2nd string, 1st fret
        [3, 3], // 3rd string, 3rd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Abm7 (G#m7)
const UKL_A_b_m_7: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// AbM7 (G#M7)
const UKL_A_b_M_7: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Adim
const UKL_A_dim: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// Am
const UKL_A_m: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 0], // 2nd string, open
        [3, 0], // 3rd string, open
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// Am6
const UKL_A_m_6: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 2], // 2nd string, 2nd fret
        [3, 0], // 3rd string, open
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// Am7
const UKL_A_m_7: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 0], // 2nd string, open
        [3, 0], // 3rd string, open
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// AmM7
const UKL_A_m_M_7: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 0], // 2nd string, open
        [3, 0], // 3rd string, open
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// AM7
const UKL_A_M_7: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 0], // 2nd string, open
        [3, 1], // 3rd string, 1st fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Asus4
const UKL_A_sus4: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 0], // 2nd string, open
        [3, 2], // 3rd string, 2nd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// B
const UKL_B: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 4], // 4th string, 4th fret
    ],
    barres: []
}];

// B6
const UKL_B_6: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// B7
const UKL_B_7: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// B9
const UKL_B_9: Chord[] = [{
    fingers: [
        [1, 4], // 1st string, 4th fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// Badd9
const UKL_B_add9: Chord[] = [{
    fingers: [
        [1, 4], // 1st string, 4th fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 4], // 4th string, 4th fret
    ],
    barres: []
}];

// Baug
const UKL_B_aug: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Bb (A#)
const UKL_B_b: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// Bb6 (A#6)
const UKL_B_b_6: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Bb7 (A#7)
const UKL_B_b_7: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Bb9 (A#9)
const UKL_B_b_9: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Bbaug (A#aug)
const UKL_B_b_aug: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 2], // 2nd string, 2nd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// Bbdim (A#dim)
const UKL_B_b_dim: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 0], // 2nd string, open
        [3, 1], // 3rd string, 1st fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Bbm (A#m)
const UKL_B_b_m: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// Bbm6 (A#m6)
const UKL_B_b_m_6: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 3], // 2nd string, 3rd fret
        [3, 1], // 3rd string, 1st fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// Bbm7 (A#m7)
const UKL_B_b_m_7: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// BbM7 (A#M7)
const UKL_B_b_M_7: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// Bdim
const UKL_B_dim: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Bm
const UKL_B_m: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 4], // 4th string, 4th fret
    ],
    barres: []
}];

// Bm6
const UKL_B_m_6: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Bm7
const UKL_B_m_7: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// BmM7
const UKL_B_m_M_7: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// BM7
const UKL_B_M_7: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 4], // 4th string, 4th fret
    ],
    barres: []
}];

// Bsus4
const UKL_B_sus4: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 4], // 3rd string, 4th fret
        [4, 4], // 4th string, 4th fret
    ],
    barres: []
}];

// C
const UKL_C: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 0], // 2nd string, open
        [3, 0], // 3rd string, open
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// C# (Db)
const UKL_C_SHP: Chord[] = [{
    fingers: [
        [1, 4], // 1st string, 4th fret
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// C#6 (Db6)
const UKL_C_SHP_6: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// C#7 (Db7)
const UKL_C_SHP_7: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// C#9 (Db9)
const UKL_C_SHP_9: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 1], // 2nd string, 1st fret
        [3, 3], // 3rd string, 3rd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// C#aug (Dbaug)
const UKL_C_SHP_aug: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// C#dim (Dbdim)
const UKL_C_SHP_dim: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 0], // 2nd string, open
        [3, 1], // 3rd string, 1st fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// C#m (Dbm)
const UKL_C_SHP_m: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 5], // 4th string, 5th fret
    ],
    barres: []
}];

// C#m6 (Dbm6)
const UKL_C_SHP_m_6: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 4], // 2nd string, 4th fret
        [3, 4], // 3rd string, 4th fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// C#m7 (Dbm7)
const UKL_C_SHP_m_7: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// C#M7 (DbM7)
const UKL_C_SHP_M_7: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// C6
const UKL_C_6: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 0], // 2nd string, open
        [3, 0], // 3rd string, open
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// C7
const UKL_C_7: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 0], // 2nd string, open
        [3, 0], // 3rd string, open
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// C9
const UKL_C_9: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 0], // 2nd string, open
        [3, 2], // 3rd string, 2nd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Cadd9
const UKL_C_add9: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 0], // 2nd string, open
        [3, 2], // 3rd string, 2nd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Caug
const UKL_C_aug: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 0], // 2nd string, open
        [3, 0], // 3rd string, open
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Cdim
const UKL_C_dim: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// Cm
const UKL_C_m: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Cm6
const UKL_C_m_6: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Cm7
const UKL_C_m_7: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// CmM7
const UKL_C_m_M_7: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 4], // 4th string, 4th fret
    ],
    barres: []
}];

// CM7
const UKL_C_M_7: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 0], // 2nd string, open
        [3, 0], // 3rd string, open
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Csus4
const UKL_C_sus4: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 1], // 2nd string, 1st fret
        [3, 0], // 3rd string, open
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// D
const UKL_D: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 2], // 2nd string, 2nd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// D# (Eb)
const UKL_D_SHP: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// D#6 (Eb6)
const UKL_D_SHP_6: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// D#7 (Eb7)
const UKL_D_SHP_7: Chord[] = [{
    fingers: [
        [1, 4], // 1st string, 4th fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// D#9 (Eb9)
const UKL_D_SHP_9: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// D#aug (Ebaug)
const UKL_D_SHP_aug: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// D#dim (Ebdim)
const UKL_D_SHP_dim: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// D#m (Ebm)
const UKL_D_SHP_m: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// D#m6 (Ebm6)
const UKL_D_SHP_m_6: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 2], // 2nd string, 2nd fret
        [3, 0], // 3rd string, open
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// D#m7 (Ebm7)
const UKL_D_SHP_m_7: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 2], // 2nd string, 2nd fret
        [3, 1], // 3rd string, 1st fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// D#M7 (EbM7)
const UKL_D_SHP_M_7: Chord[] = [{
    fingers: [
        [1, 5], // 1st string, 5th fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// D6
const UKL_D_6: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// D7
const UKL_D_7: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// D9
const UKL_D_9: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 4], // 3rd string, 4th fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// Dadd9
const UKL_D_add9: Chord[] = [{
    fingers: [
        [1, 5], // 1st string, 5th fret
        [2, 2], // 2nd string, 2nd fret
        [3, 4], // 3rd string, 4th fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// Daug
const UKL_D_aug: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 2], // 2nd string, 2nd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// Db (C#)
const UKL_D_b: Chord[] = [{
    fingers: [
        [1, 4], // 1st string, 4th fret
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Db6 (C#6)
const UKL_D_b_6: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Db7 (C#7)
const UKL_D_b_7: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Db9 (C#9)
const UKL_D_b_9: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 1], // 2nd string, 1st fret
        [3, 3], // 3rd string, 3rd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Dbaug (C#aug)
const UKL_D_b_aug: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// Dbdim (C#dim)
const UKL_D_b_dim: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 0], // 2nd string, open
        [3, 1], // 3rd string, 1st fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Dbm (C#m)
const UKL_D_b_m: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 5], // 4th string, 5th fret
    ],
    barres: []
}];

// Dbm6 (C#m6)
const UKL_D_b_m_6: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 4], // 2nd string, 4th fret
        [3, 4], // 3rd string, 4th fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Dbm7 (C#m7)
const UKL_D_b_m_7: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// DbM7 (C#M7)
const UKL_D_b_M_7: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Ddim
const UKL_D_dim: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Dm
const UKL_D_m: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// Dm6
const UKL_D_m_6: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// Dm7
const UKL_D_m_7: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// DmM7
const UKL_D_m_M_7: Chord[] = [{
    fingers: [
        [1, 4], // 1st string, 4th fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// DM7
const UKL_D_M_7: Chord[] = [{
    fingers: [
        [1, 4], // 1st string, 4th fret
        [2, 2], // 2nd string, 2nd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// Dsus4
const UKL_D_sus4: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 3], // 2nd string, 3rd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// E
const UKL_E: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 4], // 2nd string, 4th fret
        [3, 4], // 3rd string, 4th fret
        [4, 4], // 4th string, 4th fret
    ],
    barres: []
}];

// E6
const UKL_E_6: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 0], // 2nd string, open
        [3, 1], // 3rd string, 1st fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// E7
const UKL_E_7: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 0], // 2nd string, open
        [3, 2], // 3rd string, 2nd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// E9
const UKL_E_9: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Eadd9
const UKL_E_add9: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 4], // 3rd string, 4th fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Eaug
const UKL_E_aug: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 0], // 2nd string, open
        [3, 0], // 3rd string, open
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Eb (D#)
const UKL_E_b: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Eb6 (D#6)
const UKL_E_b_6: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// Eb7 (D#7)
const UKL_E_b_7: Chord[] = [{
    fingers: [
        [1, 4], // 1st string, 4th fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// Eb9 (D#9)
const UKL_E_b_9: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Ebaug (D#aug)
const UKL_E_b_aug: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Ebdim (D#dim)
const UKL_E_b_dim: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// Ebm (D#m)
const UKL_E_b_m: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// Ebm6 (D#m6)
const UKL_E_b_m_6: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 2], // 2nd string, 2nd fret
        [3, 0], // 3rd string, open
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// Ebm7 (D#m7)
const UKL_E_b_m_7: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 2], // 2nd string, 2nd fret
        [3, 1], // 3rd string, 1st fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// EbM7 (D#M7)
const UKL_E_b_M_7: Chord[] = [{
    fingers: [
        [1, 5], // 1st string, 5th fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// Edim
const UKL_E_dim: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 0], // 2nd string, open
        [3, 1], // 3rd string, 1st fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Em
const UKL_E_m: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 4], // 3rd string, 4th fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Em6
const UKL_E_m_6: Chord[] = [{
    fingers: [
        [1, 4], // 1st string, 4th fret
        [2, 3], // 2nd string, 3rd fret
        [3, 4], // 3rd string, 4th fret
        [4, 4], // 4th string, 4th fret
    ],
    barres: []
}];

// Em7
const UKL_E_m_7: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 0], // 2nd string, open
        [3, 2], // 3rd string, 2nd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// EmM7
const UKL_E_m_M_7: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 0], // 2nd string, open
        [3, 3], // 3rd string, 3rd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// EM7
const UKL_E_M_7: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 0], // 2nd string, open
        [3, 3], // 3rd string, 3rd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Esus4
const UKL_E_sus4: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 5], // 2nd string, 5th fret
        [3, 4], // 3rd string, 4th fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// F
const UKL_F: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 1], // 2nd string, 1st fret
        [3, 0], // 3rd string, open
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// F# (Gb)
const UKL_F_SHP: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 2], // 2nd string, 2nd fret
        [3, 1], // 3rd string, 1st fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// F#6 (Gb6)
const UKL_F_SHP_6: Chord[] = [{
    fingers: [
        [1, 4], // 1st string, 4th fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// F#7 (Gb7)
const UKL_F_SHP_7: Chord[] = [{
    fingers: [
        [1, 4], // 1st string, 4th fret
        [2, 2], // 2nd string, 2nd fret
        [3, 4], // 3rd string, 4th fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// F#9 (Gb9)
const UKL_F_SHP_9: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 0], // 2nd string, open
        [3, 1], // 3rd string, 1st fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// F#aug (Gbaug)
const UKL_F_SHP_aug: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 2], // 2nd string, 2nd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// F#dim (Gbdim)
const UKL_F_SHP_dim: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// F#m (Gbm)
const UKL_F_SHP_m: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 2], // 2nd string, 2nd fret
        [3, 1], // 3rd string, 1st fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// F#m6 (Gbm6)
const UKL_F_SHP_m_6: Chord[] = [{
    fingers: [
        [1, 4], // 1st string, 4th fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// F#m7 (Gbm7)
const UKL_F_SHP_m_7: Chord[] = [{
    fingers: [
        [1, 4], // 1st string, 4th fret
        [2, 2], // 2nd string, 2nd fret
        [3, 4], // 3rd string, 4th fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// F#M7 (GbM7)
const UKL_F_SHP_M_7: Chord[] = [{
    fingers: [
        [1, 4], // 1st string, 4th fret
        [2, 2], // 2nd string, 2nd fret
        [3, 5], // 3rd string, 5th fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// F6
const UKL_F_6: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// F7
const UKL_F_7: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 1], // 2nd string, 1st fret
        [3, 3], // 3rd string, 3rd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// F9
const UKL_F_9: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// Fadd9
const UKL_F_add9: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 1], // 2nd string, 1st fret
        [3, 0], // 3rd string, open
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Faug
const UKL_F_aug: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 1], // 2nd string, 1st fret
        [3, 1], // 3rd string, 1st fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// Fdim
const UKL_F_dim: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 1], // 2nd string, 1st fret
        [3, 3], // 3rd string, 3rd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Fm
const UKL_F_m: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 1], // 2nd string, 1st fret
        [3, 0], // 3rd string, open
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Fm6
const UKL_F_m_6: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Fm7
const UKL_F_m_7: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 1], // 2nd string, 1st fret
        [3, 3], // 3rd string, 3rd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// FmM7
const UKL_F_m_M_7: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 1], // 2nd string, 1st fret
        [3, 4], // 3rd string, 4th fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// FM7
const UKL_F_M_7: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 1], // 2nd string, 1st fret
        [3, 4], // 3rd string, 4th fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// Fsus4
const UKL_F_sus4: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 0], // 3rd string, open
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// G
const UKL_G: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// G# (Ab)
const UKL_G_SHP: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 4], // 2nd string, 4th fret
        [3, 3], // 3rd string, 3rd fret
        [4, 5], // 4th string, 5th fret
    ],
    barres: []
}];

// G#6 (Ab6)
const UKL_G_SHP_6: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 1], // 2nd string, 1st fret
        [3, 3], // 3rd string, 3rd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// G#7 (Ab7)
const UKL_G_SHP_7: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// G#9 (Ab9)
const UKL_G_SHP_9: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// G#aug (Abaug)
const UKL_G_SHP_aug: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 0], // 2nd string, open
        [3, 0], // 3rd string, open
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// G#dim (Abdim)
const UKL_G_SHP_dim: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// G#m (Abm)
const UKL_G_SHP_m: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 4], // 2nd string, 4th fret
        [3, 3], // 3rd string, 3rd fret
        [4, 4], // 4th string, 4th fret
    ],
    barres: []
}];

// G#m6 (Abm6)
const UKL_G_SHP_m_6: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 1], // 2nd string, 1st fret
        [3, 3], // 3rd string, 3rd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// G#m7 (Abm7)
const UKL_G_SHP_m_7: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// G#M7 (AbM7)
const UKL_G_SHP_M_7: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// G6
const UKL_G_6: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 0], // 2nd string, open
        [3, 2], // 3rd string, 2nd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// G7
const UKL_G_7: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// G9
const UKL_G_9: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// Gadd9
const UKL_G_add9: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 3], // 2nd string, 3rd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Gaug
const UKL_G_aug: Chord[] = [{
    fingers: [
        [1, 2], // 1st string, 2nd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Gb (F#)
const UKL_G_b: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 2], // 2nd string, 2nd fret
        [3, 1], // 3rd string, 1st fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// Gb6 (F#6)
const UKL_G_b_6: Chord[] = [{
    fingers: [
        [1, 4], // 1st string, 4th fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// Gb7 (F#7)
const UKL_G_b_7: Chord[] = [{
    fingers: [
        [1, 4], // 1st string, 4th fret
        [2, 2], // 2nd string, 2nd fret
        [3, 4], // 3rd string, 4th fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// Gb9 (F#9)
const UKL_G_b_9: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 0], // 2nd string, open
        [3, 1], // 3rd string, 1st fret
        [4, 1], // 4th string, 1st fret
    ],
    barres: []
}];

// Gbaug (F#aug)
const UKL_G_b_aug: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 2], // 2nd string, 2nd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// Gbdim (F#dim)
const UKL_G_b_dim: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// Gbm (F#m)
const UKL_G_b_m: Chord[] = [{
    fingers: [
        [1, 0], // 1st string, open
        [2, 2], // 2nd string, 2nd fret
        [3, 1], // 3rd string, 1st fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// Gbm6 (F#m6)
const UKL_G_b_m_6: Chord[] = [{
    fingers: [
        [1, 4], // 1st string, 4th fret
        [2, 2], // 2nd string, 2nd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// Gbm7 (F#m7)
const UKL_G_b_m_7: Chord[] = [{
    fingers: [
        [1, 4], // 1st string, 4th fret
        [2, 2], // 2nd string, 2nd fret
        [3, 4], // 3rd string, 4th fret
        [4, 2], // 4th string, 2nd fret
    ],
    barres: []
}];

// GbM7 (F#M7)
const UKL_G_b_M_7: Chord[] = [{
    fingers: [
        [1, 4], // 1st string, 4th fret
        [2, 2], // 2nd string, 2nd fret
        [3, 5], // 3rd string, 5th fret
        [4, 3], // 4th string, 3rd fret
    ],
    barres: []
}];

// Gdim
const UKL_G_dim: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 0], // 2nd string, open
        [3, 1], // 3rd string, 1st fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Gm
const UKL_G_m: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 3], // 2nd string, 3rd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Gm6
const UKL_G_m_6: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 0], // 2nd string, open
        [3, 2], // 3rd string, 2nd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Gm7
const UKL_G_m_7: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 1], // 2nd string, 1st fret
        [3, 2], // 3rd string, 2nd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// GmM7
const UKL_G_m_M_7: Chord[] = [{
    fingers: [
        [1, 1], // 1st string, 1st fret
        [2, 2], // 2nd string, 2nd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// GM7
const UKL_G_M_7: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 3], // 3rd string, 3rd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

// Gsus4
const UKL_G_sus4: Chord[] = [{
    fingers: [
        [1, 3], // 1st string, 3rd fret
        [2, 3], // 2nd string, 3rd fret
        [3, 2], // 3rd string, 2nd fret
        [4, 0], // 4th string, open
    ],
    barres: []
}];

const chordSvgMap: { [chordExposureType in ChordExposureType]: { [key: string]: Chord[] | null } } = {
    [ChordExposureType.IMAGE_GUITAR]: {
        /* A */
        "A": GTR_A,
        "A#": GTR_A_SHP,
        "A#6": GTR_A_SHP_6,
        "A#7": GTR_A_SHP_7,
        "A#9": GTR_A_SHP_9,
        "A#aug": GTR_A_SHP_aug,
        "A#+": GTR_A_SHP_aug,
        "A#dim": GTR_A_SHP_dim,
        "A#m": GTR_A_SHP_m,
        "A#m6": GTR_A_SHP_m_6,
        "A#m7": GTR_A_SHP_m_7,
        "A#M7": GTR_A_SHP_M_7,
        "A6": GTR_A_6,
        "A7": GTR_A_7,
        "A9": GTR_A_9,
        "Aadd9": GTR_A_add9,
        "Aaug": GTR_A_aug,
        "A+": GTR_A_aug,
        "Ab": GTR_A_b,
        "Ab6": GTR_A_b_6,
        "Ab7": GTR_A_b_7,
        "Ab9": GTR_A_b_9,
        "Abaug": GTR_A_b_aug,
        "Ab+": GTR_A_b_aug,
        "Abdim": GTR_A_b_dim,
        "Abm": GTR_A_b_m,
        "Abm6": GTR_A_b_m_6,
        "Abm7": GTR_A_b_m_7,
        "AbM7": GTR_A_b_M_7,
        "Adim": GTR_A_dim,
        "Am": GTR_A_m,
        "Am6": GTR_A_m_6,
        "Am7": GTR_A_m_7,
        "AmM7": GTR_A_m_M_7,
        "AM7": GTR_A_M_7,
        "Asus4": GTR_A_sus4,
        /* B */
        "B": GTR_B,
        // B# 항목들은 C로 대체 (C 와 동일)
        "B#": GTR_C,
        "B#6": GTR_C_6,
        "B#7": GTR_C_7,
        "B#9": GTR_C_9,
        "B#aug": GTR_C_aug,
        "B#+": GTR_C_aug,
        "B#dim": GTR_C_dim,
        "B#m": GTR_C_m,
        "B#m6": GTR_C_m_6,
        "B#m7": GTR_C_m_7,
        "B#M7": GTR_C_M_7,
        "B6": GTR_B_6,
        "B7": GTR_B_7,
        "B9": GTR_B_9,
        "Badd9": GTR_B_add9,
        "Baug": GTR_B_aug,
        "B+": GTR_B_aug,
        "Bb": GTR_B_b,
        "Bb6": GTR_B_b_6,
        "Bb7": GTR_B_b_7,
        "Bb9": GTR_B_b_9,
        "Bbaug": GTR_B_b_aug,
        "Bb+": GTR_B_b_aug,
        "Bbdim": GTR_B_b_dim,
        "Bbm": GTR_B_b_m,
        "Bbm6": GTR_B_b_m_6,
        "Bbm7": GTR_B_b_m_7,
        "BbM7": GTR_B_b_M_7,
        "Bdim": GTR_B_dim,
        "Bm": GTR_B_m,
        "Bm6": GTR_B_m_6,
        "Bm7": GTR_B_m_7,
        "BmM7": GTR_B_m_M_7,
        "BM7": GTR_B_M_7,
        "Bsus4": GTR_B_sus4,
        /* C */
        "C": GTR_C,
        "C#": GTR_C_SHP,
        "C#6": GTR_C_SHP_6,
        "C#7": GTR_C_SHP_7,
        "C#9": GTR_C_SHP_9,
        "C#aug": GTR_C_SHP_aug,
        "C#+": GTR_C_SHP_aug,
        "C#dim": GTR_C_SHP_dim,
        "C#m": GTR_C_SHP_m,
        "C#m6": GTR_C_SHP_m_6,
        "C#m7": GTR_C_SHP_m_7,
        "C#M7": GTR_C_SHP_M_7,
        "C6": GTR_C_6,
        "C7": GTR_C_7,
        "C9": GTR_C_9,
        "Cadd9": GTR_C_add9,
        "Caug": GTR_C_aug,
        "C+": GTR_C_aug,
        // Cb 항목들은 B로 대체 (B 와 동일)
        "Cb": GTR_B,
        "Cb6": GTR_B_6,
        "Cb7": GTR_B_7,
        "Cb9": GTR_B_9,
        "Cbaug": GTR_B_aug,
        "Cb+": GTR_B_aug,
        "Cbdim": GTR_B_dim,
        "Cbm": GTR_B_m,
        "Cbm6": GTR_B_m_6,
        "Cbm7": GTR_B_m_7,
        "CbM7": GTR_B_M_7,
        "Cdim": GTR_C_dim,
        "Cm": GTR_C_m,
        "Cm6": GTR_C_m_6,
        "Cm7": GTR_C_m_7,
        "CmM7": GTR_C_m_M_7,
        "CM7": GTR_C_M_7,
        "Csus4": GTR_C_sus4,
        /* D */
        "D": GTR_D,
        "D#": GTR_D_SHP,
        "D#6": GTR_D_SHP_6,
        "D#7": GTR_D_SHP_7,
        "D#9": GTR_D_SHP_9,
        "D#aug": GTR_D_SHP_aug,
        "D#+": GTR_D_SHP_aug,
        "D#dim": GTR_D_SHP_dim,
        "D#m": GTR_D_SHP_m,
        "D#m6": GTR_D_SHP_m_6,
        "D#m7": GTR_D_SHP_m_7,
        "D#M7": GTR_D_SHP_M_7,
        "D6": GTR_D_6,
        "D7": GTR_D_7,
        "D9": GTR_D_9,
        "Dadd9": GTR_D_add9,
        "Daug": GTR_D_aug,
        "D+": GTR_D_aug,
        "Db": GTR_D_b,
        "Db6": GTR_D_b_6,
        "Db7": GTR_D_b_7,
        "Db9": GTR_D_b_9,
        "Dbaug": GTR_D_b_aug,
        "Db+": GTR_D_b_aug,
        "Dbdim": GTR_D_b_dim,
        "Dbm": GTR_D_b_m,
        "Dbm6": GTR_D_b_m_6,
        "Dbm7": GTR_D_b_m_7,
        "DbM7": GTR_D_b_M_7,
        "Ddim": GTR_D_dim,
        "Dm": GTR_D_m,
        "Dm6": GTR_D_m_6,
        "Dm7": GTR_D_m_7,
        "DmM7": GTR_D_m_M_7,
        "DM7": GTR_D_M_7,
        "Dsus4": GTR_D_sus4,
        /* E */
        "E": GTR_E,
        // E# 항목들은 F로 대체 (F 와 동일)
        "E#": GTR_F,
        "E#6": GTR_F_6,
        "E#7": GTR_F_7,
        "E#9": GTR_F_9,
        "E#aug": GTR_F_aug,
        "E#+": GTR_F_aug,
        "E#dim": GTR_F_dim,
        "E#m": GTR_F_m,
        "E#m6": GTR_F_m_6,
        "E#m7": GTR_F_m_7,
        "E#M7": GTR_F_M_7,
        "E6": GTR_E_6,
        "E7": GTR_E_7,
        "E9": GTR_E_9,
        "Eadd9": GTR_E_add9,
        "Eaug": GTR_E_aug,
        "E+": GTR_E_aug,
        "Eb": GTR_E_b,
        "Eb6": GTR_E_b_6,
        "Eb7": GTR_E_b_7,
        "Eb9": GTR_E_b_9,
        "Ebaug": GTR_E_b_aug,
        "Eb+": GTR_E_b_aug,
        "Ebdim": GTR_E_b_dim,
        "Ebm": GTR_E_b_m,
        "Ebm6": GTR_E_b_m_6,
        "Ebm7": GTR_E_b_m_7,
        "EbM7": GTR_E_b_M_7,
        "Edim": GTR_E_dim,
        "Em": GTR_E_m,
        "Em6": GTR_E_m_6,
        "Em7": GTR_E_m_7,
        "EmM7": GTR_E_m_M_7,
        "EM7": GTR_E_M_7,
        "Esus4": GTR_E_sus4,
        /* F */
        "F": GTR_F,
        "F#": GTR_F_SHP,
        "F#6": GTR_F_SHP_6,
        "F#7": GTR_F_SHP_7,
        "F#9": GTR_F_SHP_9,
        "F#aug": GTR_F_SHP_aug,
        "F#+": GTR_F_SHP_aug,
        "F#dim": GTR_F_SHP_dim,
        "F#m": GTR_F_SHP_m,
        "F#m6": GTR_F_SHP_m_6,
        "F#m7": GTR_F_SHP_m_7,
        "F#M7": GTR_F_SHP_M_7,
        "F6": GTR_F_6,
        "F7": GTR_F_7,
        "F9": GTR_F_9,
        "Fadd9": GTR_F_add9,
        "Faug": GTR_F_aug,
        "F+": GTR_F_aug,
        // Fb 항목들은 제외 (E 와 동일)
        "Fb": GTR_E,
        "Fb6": GTR_E_6,
        "Fb7": GTR_E_7,
        "Fb9": GTR_E_9,
        "Fbaug": GTR_E_aug,
        "Fb+": GTR_E_aug,
        "Fbdim": GTR_E_dim,
        "Fbm": GTR_E_m,
        "Fbm6": GTR_E_m_6,
        "Fbm7": GTR_E_m_7,
        "FbM7": GTR_E_M_7,
        "Fdim": GTR_F_dim,
        "Fm": GTR_F_m,
        "Fm6": GTR_F_m_6,
        "Fm7": GTR_F_m_7,
        "FmM7": GTR_F_m_M_7,
        "FM7": GTR_F_M_7,
        "Fsus4": GTR_F_sus4,
        /* G */
        "G": GTR_G,
        "G#": GTR_G_SHP,
        "G#6": GTR_G_SHP_6,
        "G#7": GTR_G_SHP_7,
        "G#9": GTR_G_SHP_9,
        "G#aug": GTR_G_SHP_aug,
        "G#+": GTR_G_SHP_aug,
        "G#dim": GTR_G_SHP_dim,
        "G#m": GTR_G_SHP_m,
        "G#m6": GTR_G_SHP_m_6,
        "G#m7": GTR_G_SHP_m_7,
        "G#M7": GTR_G_SHP_M_7,
        "G6": GTR_G_6,
        "G7": GTR_G_7,
        "G9": GTR_G_9,
        "Gadd9": GTR_G_add9,
        "Gaug": GTR_G_aug,
        "G+": GTR_G_aug,
        "Gb": GTR_G_b,
        "Gb6": GTR_G_b_6,
        "Gb7": GTR_G_b_7,
        "Gb9": GTR_G_b_9,
        "Gbaug": GTR_G_b_aug,
        "Gb+": GTR_G_b_aug,
        "Gbdim": GTR_G_b_dim,
        "Gbm": GTR_G_b_m,
        "Gbm6": GTR_G_b_m_6,
        "Gbm7": GTR_G_b_m_7,
        "GbM7": GTR_G_b_M_7,
        "Gdim": GTR_G_dim,
        "Gm": GTR_G_m,
        "Gm6": GTR_G_m_6,
        "Gm7": GTR_G_m_7,
        "GmM7": GTR_G_m_M_7,
        "GM7": GTR_G_M_7,
        "Gsus4": GTR_G_sus4
    },
    [ChordExposureType.IMAGE_UKULELE]: {
        /* A */
        "A": UKL_A,
        "A#": UKL_A_SHP,
        "A#6": UKL_A_SHP_6,
        "A#7": UKL_A_SHP_7,
        "A#9": UKL_A_SHP_9,
        "A#aug": UKL_A_SHP_aug,
        "A#+": UKL_A_SHP_aug,
        "A#dim": UKL_A_SHP_dim,
        "A#m": UKL_A_SHP_m,
        "A#m6": UKL_A_SHP_m_6,
        "A#m7": UKL_A_SHP_m_7,
        "A#M7": UKL_A_SHP_M_7,
        "A6": UKL_A_6,
        "A7": UKL_A_7,
        "A9": UKL_A_9,
        "Aadd9": UKL_A_add9,
        "Aaug": UKL_A_aug,
        "A+": UKL_A_aug,
        "Ab": UKL_A_b,
        "Ab6": UKL_A_b_6,
        "Ab7": UKL_A_b_7,
        "Ab9": UKL_A_b_9,
        "Abaug": UKL_A_b_aug,
        "Ab+": UKL_A_b_aug,
        "Abdim": UKL_A_b_dim,
        "Abm": UKL_A_b_m,
        "Abm6": UKL_A_b_m_6,
        "Abm7": UKL_A_b_m_7,
        "AbM7": UKL_A_b_M_7,
        "Adim": UKL_A_dim,
        "Am": UKL_A_m,
        "Am6": UKL_A_m_6,
        "Am7": UKL_A_m_7,
        "AmM7": UKL_A_m_M_7,
        "AM7": UKL_A_M_7,
        "Asus4": UKL_A_sus4,
        /* B */
        "B": UKL_B,
        // B# 항목들은 C로 대체 (C 와 동일)
        "B#": UKL_C,
        "B#6": UKL_C_6,
        "B#7": UKL_C_7,
        "B#9": UKL_C_9,
        "B#aug": UKL_C_aug,
        "B#+": UKL_C_aug,
        "B#dim": UKL_C_dim,
        "B#m": UKL_C_m,
        "B#m6": UKL_C_m_6,
        "B#m7": UKL_C_m_7,
        "B#M7": UKL_C_M_7,
        "B6": UKL_B_6,
        "B7": UKL_B_7,
        "B9": UKL_B_9,
        "Badd9": UKL_B_add9,
        "Baug": UKL_B_aug,
        "B+": UKL_B_aug,
        "Bb": UKL_B_b,
        "Bb6": UKL_B_b_6,
        "Bb7": UKL_B_b_7,
        "Bb9": UKL_B_b_9,
        "Bbaug": UKL_B_b_aug,
        "Bb+": UKL_B_b_aug,
        "Bbdim": UKL_B_b_dim,
        "Bbm": UKL_B_b_m,
        "Bbm6": UKL_B_b_m_6,
        "Bbm7": UKL_B_b_m_7,
        "BbM7": UKL_B_b_M_7,
        "Bdim": UKL_B_dim,
        "Bm": UKL_B_m,
        "Bm6": UKL_B_m_6,
        "Bm7": UKL_B_m_7,
        "BmM7": UKL_B_m_M_7,
        "BM7": UKL_B_M_7,
        "Bsus4": UKL_B_sus4,
        /* C */
        "C": UKL_C,
        "C#": UKL_C_SHP,
        "C#6": UKL_C_SHP_6,
        "C#7": UKL_C_SHP_7,
        "C#9": UKL_C_SHP_9,
        "C#aug": UKL_C_SHP_aug,
        "C#+": UKL_C_SHP_aug,
        "C#dim": UKL_C_SHP_dim,
        "C#m": UKL_C_SHP_m,
        "C#m6": UKL_C_SHP_m_6,
        "C#m7": UKL_C_SHP_m_7,
        "C#M7": UKL_C_SHP_M_7,
        "C6": UKL_C_6,
        "C7": UKL_C_7,
        "C9": UKL_C_9,
        "Cadd9": UKL_C_add9,
        "Caug": UKL_C_aug,
        "C+": UKL_C_aug,
        // Cb 항목들은 B로 대체 (B 와 동일)
        "Cb": UKL_B,
        "Cb6": UKL_B_6,
        "Cb7": UKL_B_7,
        "Cb9": UKL_B_9,
        "Cbaug": UKL_B_aug,
        "Cb+": UKL_B_aug,
        "Cbdim": UKL_B_dim,
        "Cbm": UKL_B_m,
        "Cbm6": UKL_B_m_6,
        "Cbm7": UKL_B_m_7,
        "CbM7": UKL_B_M_7,
        "Cdim": UKL_C_dim,
        "Cm": UKL_C_m,
        "Cm6": UKL_C_m_6,
        "Cm7": UKL_C_m_7,
        "CmM7": UKL_C_m_M_7,
        "CM7": UKL_C_M_7,
        "Csus4": UKL_C_sus4,
        /* D */
        "D": UKL_D,
        "D#": UKL_D_SHP,
        "D#6": UKL_D_SHP_6,
        "D#7": UKL_D_SHP_7,
        "D#9": UKL_D_SHP_9,
        "D#aug": UKL_D_SHP_aug,
        "D#+": UKL_D_SHP_aug,
        "D#dim": UKL_D_SHP_dim,
        "D#m": UKL_D_SHP_m,
        "D#m6": UKL_D_SHP_m_6,
        "D#m7": UKL_D_SHP_m_7,
        "D#M7": UKL_D_SHP_M_7,
        "D6": UKL_D_6,
        "D7": UKL_D_7,
        "D9": UKL_D_9,
        "Dadd9": UKL_D_add9,
        "Daug": UKL_D_aug,
        "D+": UKL_D_aug,
        "Db": UKL_D_b,
        "Db6": UKL_D_b_6,
        "Db7": UKL_D_b_7,
        "Db9": UKL_D_b_9,
        "Dbaug": UKL_D_b_aug,
        "Db+": UKL_D_b_aug,
        "Dbdim": UKL_D_b_dim,
        "Dbm": UKL_D_b_m,
        "Dbm6": UKL_D_b_m_6,
        "Dbm7": UKL_D_b_m_7,
        "DbM7": UKL_D_b_M_7,
        "Ddim": UKL_D_dim,
        "Dm": UKL_D_m,
        "Dm6": UKL_D_m_6,
        "Dm7": UKL_D_m_7,
        "DmM7": UKL_D_m_M_7,
        "DM7": UKL_D_M_7,
        "Dsus4": UKL_D_sus4,
        /* E */
        "E": UKL_E,
        // E# 항목들은 F로 대체 (F 와 동일)
        "E#": UKL_F,
        "E#6": UKL_F_6,
        "E#7": UKL_F_7,
        "E#9": UKL_F_9,
        "E#aug": UKL_F_aug,
        "E#+": UKL_F_aug,
        "E#dim": UKL_F_dim,
        "E#m": UKL_F_m,
        "E#m6": UKL_F_m_6,
        "E#m7": UKL_F_m_7,
        "E#M7": UKL_F_M_7,
        "E6": UKL_E_6,
        "E7": UKL_E_7,
        "E9": UKL_E_9,
        "Eadd9": UKL_E_add9,
        "Eaug": UKL_E_aug,
        "E+": UKL_E_aug,
        "Eb": UKL_E_b,
        "Eb6": UKL_E_b_6,
        "Eb7": UKL_E_b_7,
        "Eb9": UKL_E_b_9,
        "Ebaug": UKL_E_b_aug,
        "Eb+": UKL_E_b_aug,
        "Ebdim": UKL_E_b_dim,
        "Ebm": UKL_E_b_m,
        "Ebm6": UKL_E_b_m_6,
        "Ebm7": UKL_E_b_m_7,
        "EbM7": UKL_E_b_M_7,
        "Edim": UKL_E_dim,
        "Em": UKL_E_m,
        "Em6": UKL_E_m_6,
        "Em7": UKL_E_m_7,
        "EmM7": UKL_E_m_M_7,
        "EM7": UKL_E_M_7,
        "Esus4": UKL_E_sus4,
        /* F */
        "F": UKL_F,
        "F#": UKL_F_SHP,
        "F#6": UKL_F_SHP_6,
        "F#7": UKL_F_SHP_7,
        "F#9": UKL_F_SHP_9,
        "F#aug": UKL_F_SHP_aug,
        "F#+": UKL_F_SHP_aug,
        "F#dim": UKL_F_SHP_dim,
        "F#m": UKL_F_SHP_m,
        "F#m6": UKL_F_SHP_m_6,
        "F#m7": UKL_F_SHP_m_7,
        "F#M7": UKL_F_SHP_M_7,
        "F6": UKL_F_6,
        "F7": UKL_F_7,
        "F9": UKL_F_9,
        "Fadd9": UKL_F_add9,
        "Faug": UKL_F_aug,
        "F+": UKL_F_aug,
        // Fb 항목들은 제외 (E 와 동일)
        "Fb": UKL_E,
        "Fb6": UKL_E_6,
        "Fb7": UKL_E_7,
        "Fb9": UKL_E_9,
        "Fbaug": UKL_E_aug,
        "Fb+": UKL_E_aug,
        "Fbdim": UKL_E_dim,
        "Fbm": UKL_E_m,
        "Fbm6": UKL_E_m_6,
        "Fbm7": UKL_E_m_7,
        "FbM7": UKL_E_M_7,
        "Fdim": UKL_F_dim,
        "Fm": UKL_F_m,
        "Fm6": UKL_F_m_6,
        "Fm7": UKL_F_m_7,
        "FmM7": UKL_F_m_M_7,
        "FM7": UKL_F_M_7,
        "Fsus4": UKL_F_sus4,
        /* G */
        "G": UKL_G,
        "G#": UKL_G_SHP,
        "G#6": UKL_G_SHP_6,
        "G#7": UKL_G_SHP_7,
        "G#9": UKL_G_SHP_9,
        "G#aug": UKL_G_SHP_aug,
        "G#+": UKL_G_SHP_aug,
        "G#dim": UKL_G_SHP_dim,
        "G#m": UKL_G_SHP_m,
        "G#m6": UKL_G_SHP_m_6,
        "G#m7": UKL_G_SHP_m_7,
        "G#M7": UKL_G_SHP_M_7,
        "G6": UKL_G_6,
        "G7": UKL_G_7,
        "G9": UKL_G_9,
        "Gadd9": UKL_G_add9,
        "Gaug": UKL_G_aug,
        "G+": UKL_G_aug,
        "Gb": UKL_G_b,
        "Gb6": UKL_G_b_6,
        "Gb7": UKL_G_b_7,
        "Gb9": UKL_G_b_9,
        "Gbaug": UKL_G_b_aug,
        "Gb+": UKL_G_b_aug,
        "Gbdim": UKL_G_b_dim,
        "Gbm": UKL_G_b_m,
        "Gbm6": UKL_G_b_m_6,
        "Gbm7": UKL_G_b_m_7,
        "GbM7": UKL_G_b_M_7,
        "Gdim": UKL_G_dim,
        "Gm": UKL_G_m,
        "Gm6": UKL_G_m_6,
        "Gm7": UKL_G_m_7,
        "GmM7": UKL_G_m_M_7,
        "GM7": UKL_G_M_7,
        "Gsus4": UKL_G_sus4
    },
    [ChordExposureType.TEXT_GUITAR]: {},
    [ChordExposureType.TEXT_UKULELE]: {}
};

chordSvgMap[ChordExposureType.TEXT_GUITAR] = chordSvgMap[ChordExposureType.IMAGE_GUITAR];
chordSvgMap[ChordExposureType.TEXT_UKULELE] = chordSvgMap[ChordExposureType.IMAGE_UKULELE];

export default chordSvgMap;