const keys = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"];

const enHarmonics: { [key: string]: string } = {
    "Cb": "B",
    "Db": "C#",
    "Eb": "D#",
    "Fb": "E",
    "Gb": "F#",
    "Ab": "G#",
    "Bb": "A#",
    "B#": "C",
    "E#": "F"
};

const normalizeKey = (key: string): string => {
    return enHarmonics[key] || key;
}

export const calculateCapoPosition = (originalKey: string | null, targetKey: string | null): number => {
    if (!originalKey || !targetKey) {
        return 0;
    }
    const normalizedOriginalKey = normalizeKey(originalKey);
    const normalizedTargetKey = normalizeKey(targetKey);

    const originalIndex = keys.indexOf(normalizedOriginalKey);
    const targetIndex = keys.indexOf(normalizedTargetKey);

    if (originalIndex === -1 || targetIndex === -1) {
        console.log(`normalizedOriginalKey: ${normalizedOriginalKey}, normalizedTargetKey: ${normalizedTargetKey}`);
        throw new Error("Invalid key provided.");
    }

    return (originalIndex - targetIndex + 12) % 12;
}