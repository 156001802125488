import React, { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const PwaInstallButton: React.FC = () => {
    const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
    const [showInstallButton, setShowInstallButton] = useState(false);
    const [isSafari, setIsSafari] = useState(false);

    useEffect(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        const isSafariBrowser = /safari/.test(userAgent) && !/chrome/.test(userAgent);

        // PWA가 이미 설치된 경우 버튼 숨기기
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
        if (isStandalone) {
            setShowInstallButton(false);
            return;
        }

        if (isSafariBrowser) {
            setIsSafari(true);
            setShowInstallButton(true); // Safari에서는 설치 전에는 버튼을 노출
        } else {
            const handleBeforeInstallPrompt = (e: Event) => {
                e.preventDefault();
                setDeferredPrompt(e);
                setShowInstallButton(true);
            };

            window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

            return () => {
                window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
            };
        }
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult: { outcome: string }) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                setDeferredPrompt(null);
                setShowInstallButton(false);
            });
        }
    };

    const handleSafariInstallClick = () => {
        const isIOS = /iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase());
        if (isIOS) {
            alert("iOS 에서 PWA 를 설치하려면 Safari 의 공유 버튼을 누르고 [홈 화면에 추가] 를 선택하세요.(Safari 에서만 진행 가능)");
        } else {
            alert("MacOS > Safari 브라우저에서는 PWA 설치가 지원되지 않습니다. Chrome 등 다른 브라우저에서 진행하세요.");
        }
    };


    if (!showInstallButton) {
        return null;
    }

    return (
        <Nav.Link
            onClick={isSafari ? handleSafariInstallClick : handleInstallClick}
            className="nav-item"
        >
            <FontAwesomeIcon icon={faDownload} />
        </Nav.Link>
    );
};

export default PwaInstallButton;
