import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import FavoriteChordSheetItems from "../components/FavoriteChordSheetItems";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDoorOpen, faUserAlt} from "@fortawesome/free-solid-svg-icons";

const FavoriteChordSheetListPage = () => {
    const { isAuthenticated } = useAuth();
    if (!isAuthenticated) {
        return (
            <Container className="mt-5">
                <Row className="justify-content-md-center">
                    <Col md={6}>
                        <Card className="p-4">
                            <div className="text-center">
                                <b>【나의 목록】</b> 메뉴는,<br/>
                                <Button
                                    variant="outline-success"
                                    as={Link as any}
                                    to="/login"
                                    className="btn-sm"
                                ><FontAwesomeIcon icon={faDoorOpen}/> <FontAwesomeIcon icon={faUserAlt}/></Button> 완료 후 이용할 수 있습니다.<p/>
                                <b>자주 연주하는 악보에 대해 【즐겨찾기】 및 【목록관리】, 【Key 저장】 등 편리한 기능을 제공합니다.</b>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
    return (
        <FavoriteChordSheetItems/>
    );
};

export default FavoriteChordSheetListPage;
