import axios from 'axios';
import { logout } from '../auth/auth';

const protocol = window.location.protocol; // 현재 페이지의 프로토콜 (http: 또는 https:)
const api = axios.create({
    baseURL: `${protocol}//${process.env.REACT_APP_BE_API_DOMAIN}/api`
});

api.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => Promise.reject(error)
);

api.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            // JWT Token 만료 또는 유효하지 않은 경우
            logout();
        }
        return Promise.reject(error);
    }
);

export default api;
