import React from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorOpen, faUserAlt, faRunning, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

interface AuthButtonProps {
    isAuthenticated: boolean;
    setIsAuthenticated: (value: boolean) => void;
}

const AuthButton: React.FC<AuthButtonProps> = ({ isAuthenticated, setIsAuthenticated }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        const confirmLogout = window.confirm('로그아웃 하시겠습니까?');
        if (confirmLogout) {
            localStorage.removeItem('token');
            setIsAuthenticated(false);
            navigate('/');
        }
    };

    return (
        <>
            {isAuthenticated ? (
                <Button
                    variant="outline-warning"
                    onClick={handleLogout}
                    className="nav-item btn-sm"
                >
                    <FontAwesomeIcon icon={faRunning} /> <FontAwesomeIcon icon={faSignOutAlt} />
                </Button>
            ) : (
                <Button
                    variant="outline-success"
                    as={Link as any}
                    to="/login"
                    className="nav-item btn-sm"
                >
                    <FontAwesomeIcon icon={faDoorOpen} /> <FontAwesomeIcon icon={faUserAlt} />
                </Button>
            )}
        </>
    );
};

export default AuthButton;
