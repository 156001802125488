import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSprout, faListAlt, faBookmark, faGuitar, faBullhorn } from '@fortawesome/free-solid-svg-icons';
import '../assets/styles/NavigationBar.css';
import { SESSION_KEY_SEARCH_INFO } from "../constants/sessionKeys";
import PwaInstallButton from "./PwaInstallButton";
import AuthButton from "./AuthButton";
import { useAuth } from "../contexts/AuthContext";

const NavigationBar: React.FC = () => {
    const navigate = useNavigate();
    const { isAuthenticated, setIsAuthenticated } = useAuth();
    const [expanded, setExpanded] = useState<boolean>(true);

    const handleNavLinkClick = (path: string) => {
        sessionStorage.removeItem(SESSION_KEY_SEARCH_INFO);
        navigate(path);
    };

    return (
        <Navbar bg="light" expand="lg" expanded={expanded} onToggle={() => setExpanded(!expanded)}>
            <Container>
                <Navbar.Brand as={Link} to="/" onClick={() => handleNavLinkClick("/")}>
                    <FontAwesomeIcon icon={faSprout} style={{ color: 'darkgreen' }} />
                    <span style={{ color: 'darkgreen' }}>Bom</span><span style={{ color: 'lightpink' }}>ione</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="mt-2">
                    <Nav className="me-auto d-flex flex-row align-items-center w-100 justify-content-between">
                        <div className="d-flex flex-row">
                            <Nav.Link onClick={() => handleNavLinkClick("/chord-sheets")} className="nav-item"><FontAwesomeIcon icon={faListAlt} /></Nav.Link>
                            <Nav.Link onClick={() => handleNavLinkClick("/chord-sheets/favorites")} className="nav-item"><FontAwesomeIcon icon={faBookmark} /></Nav.Link>
                            <Nav.Link onClick={() => handleNavLinkClick("/chords")} className="nav-item"><FontAwesomeIcon icon={faGuitar} /></Nav.Link>
                            <Nav.Link onClick={() => handleNavLinkClick("/notices")} className="nav-item"><FontAwesomeIcon icon={faBullhorn} /></Nav.Link>
                            <PwaInstallButton />
                        </div>
                        <div>
                            <AuthButton isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavigationBar;
