import React from 'react';
import ChordSheetItem from "../components/ChordSheetItem";

const ChordSheetPage = () => {
    return (
        <div>
            <ChordSheetItem />
        </div>
    );
};

export default ChordSheetPage;
