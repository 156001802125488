import ChordSheetJS, { Song, Chord } from "chordsheetjs";
import { ChordExposureType, ChordExposureTextType } from "../enums/ChordExposureType";
import { getChordSvg, renderChordSvg } from "./chordSvgUtils";

const getChordProParser = () => new ChordSheetJS.ChordProParser();

const isEnharmonic = (chordText: string): boolean =>
    ['A#', 'D#', 'Gb', 'Ab', 'Db'].some(note => chordText.includes(note));

const normalizeChord = (chordText: string, key: string): string | null =>
    Chord.parse(chordText)?.normalize(key)?.toString() || null;

const replaceChordText = (chordText: string): string =>
    chordText.replace(/ma(j)?/, 'M');

const createSpanElement = (): HTMLSpanElement => {
    return document.createElement('span');
};

const buildChordText = (mainChordText: string, bassChordText: string): string =>
    `${mainChordText}${bassChordText ? '<span class="bass-chord-text">/' + bassChordText + '</span>' : ''}`

export const parseChordSheetText = (chordSheetText: string): Song =>
    getChordProParser().parse(chordSheetText);

export const toText = (song: Song): string => {
    const formatter = new ChordSheetJS.TextFormatter({ normalizeChords: false });
    return formatter.format(song);
};

export const toChordPro = (song: Song): string => {
    const formatter = new ChordSheetJS.ChordProFormatter({ normalizeChords: false });
    return formatter.format(song);
};

export const toHtml = (song: Song): string => {
    const formatter = new ChordSheetJS.HtmlDivFormatter({ normalizeChords: false });
    return formatter.format(song);
};

export const toChordHtml = (song: Song, chordExposureType: ChordExposureType): string => {
    const htmlText = toHtml(song);
    //console.log(`htmlText: ${htmlText}`);
    const doc = new DOMParser().parseFromString(htmlText, 'text/html');

    doc.querySelectorAll('.lyrics').forEach((lyricsElement) => {
        lyricsElement.innerHTML = lyricsElement.innerHTML.replace(/\s/g, '&nbsp;');
    });

    doc.querySelectorAll('.chord').forEach((chordElement: Element) => {
        let [mainChordText, bassChordText] = chordElement.textContent ? chordElement.textContent.split('/') : ["", ""];

        if (mainChordText) {
            if (isEnharmonic(mainChordText)) {
                mainChordText = normalizeChord(mainChordText, 'F#m')!;
            }
            mainChordText = replaceChordText(mainChordText);
            (chordElement as HTMLElement).setAttribute('data-main-chord', mainChordText);
        }

        if (ChordExposureTextType.includes(chordExposureType)) {
            chordElement.innerHTML = buildChordText(mainChordText, bassChordText);
        } else {
            const span = createSpanElement();
            if (mainChordText) {
                const chordSvg = getChordSvg(chordExposureType, mainChordText);
                if (chordSvg) {
                    span.className = 'image-chord';
                    renderChordSvg(span, chordExposureType, mainChordText);
                } else {
                    span.className = 'image-chord--text';
                    span.innerHTML = buildChordText(mainChordText, bassChordText);
                }
            } else {
                span.className = 'image-chord--empty';
            }
            chordElement.parentNode?.replaceChild(span, chordElement);
        }
    });

    return doc.documentElement.outerHTML;
};

export const transposeKey = (song: Song, key: string): Song => {
    const isFlat = key.endsWith('b');
    let transposeDistance = song.getTransposeDistance(key);
    if (isFlat) {
        transposeDistance -= 12;
    }
    return song.transpose(transposeDistance);
};
