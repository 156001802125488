export enum ChordExposureType {
    TEXT_GUITAR = "TEXT_GUITAR",
    TEXT_UKULELE = "TEXT_UKULELE",
    IMAGE_GUITAR = "IMAGE_GUITAR",
    IMAGE_UKULELE = "IMAGE_UKULELE"
}

export const ChordExposureTextType: ChordExposureType[] = [
    ChordExposureType.TEXT_GUITAR,
    ChordExposureType.TEXT_UKULELE
];

export const ChordExposureImageType: ChordExposureType[] = [
    ChordExposureType.IMAGE_GUITAR,
    ChordExposureType.IMAGE_UKULELE
];

export const DEFAULT_CHORD_EXPOSURE_TYPE = ChordExposureType.TEXT_GUITAR;
