import { AxiosResponse } from 'axios';
import { ChordSheet } from '../models/ChordSheet';
import PageResponse from '../models/PageResponse';
import api from './api';
import { ChordSheetSearchCriteria } from '../models/ChordSheetSearchCriteria';

export const fetchChordSheets = async (searchCriteria: ChordSheetSearchCriteria): Promise<PageResponse<ChordSheet>> => {
    try {
        const { searchType, searchText, pageNumber, pageSize, sortField, sortDirection } = searchCriteria;
        const response: AxiosResponse<PageResponse<ChordSheet>> = await api.get("/chord-sheets", {
            params: {
                searchType: searchType || undefined,
                searchText: searchText || undefined,
                page: pageNumber,
                size: pageSize,
                sortField: sortField?.toUpperCase(),
                sortDirection: sortDirection
            }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Error fetchChordSheets: ${error}`);
    }
};

export const fetchFavoriteChordSheets = async (): Promise<Array<ChordSheet>> => {
    try {
        const response: AxiosResponse<Array<ChordSheet>> = await api.get("/chord-sheets/favorites");
        return response.data;
    } catch (error) {
        throw new Error(`Error fetchFavoriteChordSheets: ${error}`);
    }
};

export const fetchChordSheet = async (id: number): Promise<ChordSheet> => {
    try {
        const response: AxiosResponse<ChordSheet> = await api.get(`/chord-sheets/${id}`);
        return response.data;
    } catch (error) {
        throw new Error(`Error fetchChordSheet: ${error}`);
    }
};

export const addFavorite = async (chordSheetId: number): Promise<void> => {
    try {
        await api.post(`/chord-sheets/${chordSheetId}/favorites`);
    } catch (error) {
        throw new Error(`Error addFavorite: ${error}`);
    }
};

export const removeFavorite = async (chordSheetId: number): Promise<void> => {
    try {
        await api.delete(`/chord-sheets/${chordSheetId}/favorites`);
    } catch (error) {
        throw new Error(`Error removeFavorite: ${error}`);
    }
};

export const modifyFavoriteChordKey = async (chordSheetId: number, chordKey: string): Promise<void> => {
    try {
        await api.patch(`/chord-sheets/${chordSheetId}/favorites/chord-keys/${chordKey}`);
    } catch (error) {
        throw new Error(`Error modifyFavoriteChordKey: ${error}`);
    }
};

export const updateFavoriteChordSheetSortOrders = async (orderedIds: number[]): Promise<void> => {
    await api.patch(`/chord-sheets/favorites/sort-orders`, orderedIds);
};
