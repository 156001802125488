import {Chord, ChordStyle, SVGuitarChord} from 'svguitar';
import {ChordExposureType} from "../enums/ChordExposureType";
import chordSvgMap from "../constants/chordSvgMap";

const configureMap = {
    [ChordExposureType.IMAGE_GUITAR]: {
        titleFontSize: 120,
        fretSize: 1,
        fingerSize: 1,
        strokeWidth: 5,
        strings: 6,
        frets: 5
    },
    [ChordExposureType.IMAGE_UKULELE]: {
        titleFontSize: 120,
        fretSize: 0.7,
        fingerSize: 0.6,
        strokeWidth: 5,
        strings: 4,
        frets: 4,
    },
    [ChordExposureType.TEXT_GUITAR]: {},
    [ChordExposureType.TEXT_UKULELE]: {},
};

configureMap[ChordExposureType.TEXT_GUITAR] = configureMap[ChordExposureType.IMAGE_GUITAR];
configureMap[ChordExposureType.TEXT_UKULELE] = configureMap[ChordExposureType.IMAGE_UKULELE];

export const getChordSvg = (chordExposureType: ChordExposureType, chordName: string, index: number = 0): Chord | null => {
    const chords = chordSvgMap[chordExposureType]?.[chordName];
    if (!chords || chords.length === 0) {
        return null; // 해당 코드가 없거나 포지션이 없는 경우 null 반환
    }
    return chords[index] || null; // 인덱스가 범위를 벗어나면 null 반환
};

export const renderChordSvg = (span: HTMLSpanElement, chordExposureType: ChordExposureType, chordName: string, style: ChordStyle = ChordStyle.normal) => {
    span.innerHTML = '';
    const chordSvg = getChordSvg(chordExposureType, chordName);
    if (!chordSvg) {
        return
    }
    const chord = new SVGuitarChord(span)
        .configure({
            title: chordName,
            style: style,
            ...configureMap[chordExposureType],
        })
        .chord(chordSvg);
    chord.draw();
};
