import {AxiosResponse} from 'axios';
import {UserLoginRequest} from '../models/UserLoginRequest';
import {UserRegisterRequest} from '../models/UserRegisterRequest';
import {UserJwtResponse} from '../models/UserJwtResponse';
import api from './api';

export const login = async (loginRequest: UserLoginRequest): Promise<AxiosResponse<UserJwtResponse>> => {
    try {
        return await api.post('/auth/login', loginRequest);
    } catch (error) {
        throw error;
    }
};

export const register = async (registerRequest: UserRegisterRequest): Promise<void> => {
    try {
        await api.post('/auth/register', registerRequest);
    } catch (error) {
        throw error;
    }
};
