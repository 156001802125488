export const saveToSession = (key: string, value: string) => {
    sessionStorage.setItem(key, value);
};

export const loadFromSession = <T extends Record<string, string>>(key: string, object: T, defaultValue: T[keyof T]): T[keyof T] => {
    const storedValue = sessionStorage.getItem(key);
    return storedValue && Object.values(object).includes(storedValue as unknown as T[keyof T])
        ? (storedValue as unknown as T[keyof T])
        : defaultValue;
};

export const saveObjectToSession = (key: string, value: Record<string, any>) => {
    sessionStorage.setItem(key, JSON.stringify(value));
};

export const loadObjectFromSession = <T extends Record<string, any>>(key: string, defaultValue: T): T => {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) as T : defaultValue;
};
